import React from 'react'
import classNames from "classnames"
import PropTypes from 'prop-types'
import loader from '../../icons/loader.gif'
import loaderHV from '../../icons/loader_hv.gif'
import './Loader.scss'


const Loader = ({ isHV }) => {
  return (
    <img className={classNames("loader", {"hv": isHV})} alt="" src={isHV ? loaderHV : loader} />
  );
};

Loader.propTypes = {
  /**
   * HV logo (Hitachi)
   */
  isHV: PropTypes.bool,
};

Loader.defaultProps = {
  isHV: false
};

export default Loader