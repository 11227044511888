import _ from 'lodash'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createRootReducer from './reducers'

export const history = createBrowserHistory()

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history), 
);

const appReducer = (state, action) => {
  if (action.type === 'USER.SET_USER_DATA' && _.isEmpty(action.payload)) { // Just did logout
    storage.removeItem('persist:root')
    // TODO: it causes a warning on logout. In order to solve it, you need to understand why there is an empty calc backend request after logout
    state["SIZER_CALCULATOR"] = undefined
    // return persistedReducer(undefined, action)
  }
  return persistedReducer(state, action);
}

export default function configureStore(preloadedState) {
  const store = createStore(
    appReducer, // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        // ... other middlewares ...
      ),
    ),
  )
  const persistor = persistStore(store)

  return { store, persistor }
}