import React, { memo } from 'react'
import { useDispatch } from "react-redux"

import { Radio } from '/src/storybook/components/Radio'
import { setRequirement } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const Network = memo(({handlerWithResultsScroll, network}) => {
  const dispatch = useDispatch()

  return (
    <div className="requirement-row">
      <b className="for-radio">Customer Network</b>
      <Radio key={network} name="network" selected={network} items={[
        {label: "100GB", value: '100'},
        {label: "200GB", value: '200'},
        {label: "400GB", value: '400'}
      ]} onSelected={handlerWithResultsScroll(newNetwork => {
        dispatch(setRequirement({network: newNetwork}))
      })}/>
    </div>
  )
})

export default Network