import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import './Tabs.scss'

/**
 * Primary UI component for user interaction
 */
const Tabs = ({ selected, tabs, onSelected, ...props }) => {
  const [selectedTab, setSelectedTabs] = useState(selected || _.get(tabs, [0, 'name']))
  const activeTab = _.find(tabs, ({name}) => name === selectedTab)
  
  const onClick = (tabName) => {
    setSelectedTabs(tabName)
    if (_.isFunction(onSelected)) {
      onSelected(tabName)
    }
  }

  return (
    <div className="tabs-container">
      {_.map(tabs, ({name}) => (
        <div 
          key={_.kebabCase(name)}
          className={classNames("tab", _.kebabCase(name), {selected: name === selectedTab})} 
          onClick={() => onClick(name)} {...props}
        >
          {name}
        </div>
      ))}
      <div>
        {_.get(activeTab, 'content')}
      </div>     
    </div>
  )
}

Tabs.propTypes = {
  /**
   * Tabs Items
   */
  tabs: PropTypes.array,
  /**
   * Selected item
   */
  selected: PropTypes.string,
  /**
   * Optional change handler
   */
  onSelected: PropTypes.func,
};

Tabs.defaultProps = {
  onSelected: undefined,
}

export default Tabs