import _ from 'lodash'
import React, { memo } from 'react'
import { Button } from '/src/storybook/components/Button'
import { Logo } from '/src/storybook/components/Logo'
import config from '/src/config'
import './Login.scss'


const Login = memo(() => {
  const adminSite = _.includes(window.location.href, '/admin')
  const login = () => {
    config.AUTH_CLIENT.signInWithRedirect({redirectUri: `${window.location.origin}${adminSite ? '/admin' : ''}`})
  }

  return (
    <div className="login">
      <Logo/>
      <div>
        <h1>
          Hi,
        </h1>
        If you are a <b>Weka</b> employee, please login here. If not feel free to navigate to <a href="https://www.weka.io">weka.io</a> or <a href="https://docs.weka.io">docs.weka.io</a>

        <Button okta onClick={login} label="Sign in with Okta" />
      </div>
    </div>
  )
})

export default Login
