import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import { Input } from '../../components/Input'
import './RadioInput.scss'

/**
 * Primary UI component for user interaction
 */
const RadioInput = ({ name, selected, items, onSelected }) => {
  const [selectedItem, setSelectedItem] = useState(selected)

  useEffect(() => {
    if (!selected && items.length) {
      setSelectedItem(items[0].radioValue)
    }
  }, [setSelectedItem, items, selected]);
  
  const onChange = (e) => {
    setSelectedItem(e.target.value)
    onSelected(e.target.value)
  }
  return (
    <div className={`radio-input-component with-${_.size(items)}`}>
      { _.map(items, item => {
        const { radioValue, onInputChange, value, leftLabel, rightLabel } = item
        const checked = radioValue === selectedItem
        return (
          <label className="radio-element radio-before" key={radioValue}>
            <span className="radio-input">
              <input 
                type="radio" 
                name={name} 
                value={radioValue} 
                checked={checked} 
                onChange={onChange}/>
              <span className="radio-control"></span>
            </span>
            <span className={classNames("radio-label", {'disabled': !checked})}>
              <div>
                <b>{leftLabel}</b>
              </div>
              <Input
                size="small" 
                value={value}
                isNumber
                disabled={!checked}
                onChange={(inputValue) => onInputChange(inputValue)}
              />
              <b>{rightLabel}</b>    
            </span>
          </label>
        )
      })}
    </div>
  )
}

RadioInput.propTypes = {
  /**
   * Radio name
   */
  name: PropTypes.string,
  /**
   * Radio Input Items
   */
  items: PropTypes.array,
  /**
   * Selected item
   */
  selected: PropTypes.string,
  /**
   * Optional change handler
   */
  onSelected: PropTypes.func,
};

RadioInput.defaultProps = {
  onSelected: undefined,
}

export default RadioInput
