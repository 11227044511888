import React, { memo, useState } from 'react'

import classNames from 'classnames'
import ArrowDown from '/src/storybook/icons/ArrowDown'
import Reset from '/src/storybook/icons/Reset'
import { useDispatch } from 'react-redux'
import { setCustomRaFields, setRequirement } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'
import HotSpare from './Fields/HotSpare'
import Network from './Fields/Network'
import RAMSize from './Fields/RAMSize'
import Stripe from './Fields/Stripe'
import WarmTBUsage from './Fields/WarmTBUsage'


const AdvancedView = memo(({
    allCustomizedFields,
    bySizingTab,
    advancedOpen,
    handlerWithResultsScroll,
    changed,
    network,
    original,
    showWarmOptions,
    setAdvancedOpen,
    warmCostAndSpace
  }) => {
  const dispatch = useDispatch()

  return (
    <div className={classNames("advanced", {open: advancedOpen})} key={`advanced-${changed}`}>
      <div className="advanced-title" onClick={() => setAdvancedOpen(!advancedOpen)}>
        <div>
          Advanced
          { changed && (
            <span> (Changes were made)</span>
          )}
        </div>
        <ArrowDown/>
      </div>
      <Network
        handlerWithResultsScroll={handlerWithResultsScroll}
        network={network}
      />
      { bySizingTab &&
        <>
          <Stripe
            allCustomizedFields={allCustomizedFields}
            handlerWithResultsScroll={handlerWithResultsScroll}
          />
          <HotSpare
            allCustomizedFields={allCustomizedFields}
            handlerWithResultsScroll={handlerWithResultsScroll}
          />
          <RAMSize
            allCustomizedFields={allCustomizedFields}
            handlerWithResultsScroll={handlerWithResultsScroll}
          />
        </>
      }
      { showWarmOptions &&
        <WarmTBUsage
          handlerWithResultsScroll={handlerWithResultsScroll}
          warmCostAndSpace={warmCostAndSpace}
        />
      }
      <div className={classNames("requirement-row reset", {changed})}>
        <b></b>
        <div onClick={handlerWithResultsScroll(() => {
          dispatch(setRequirement({network: '400', warmCostAndSpace: true}))
          if (bySizingTab) {
            dispatch(setCustomRaFields({...allCustomizedFields, ...original}))
          }
        })}>
          <Reset/>
          Reset
        </div>
      </div>
    </div>
  )
})

export default AdvancedView
