import React, { memo } from "react"
import _ from "lodash"
import classNames from "classnames"
import Info from '/src/storybook/icons/Info'
import { TooltipHolder } from '/src/storybook/components/TooltipHolder'
import { serversEnclosuresHelpValues } from "/src/routes/SizerCalculator/ResultsPane/ResultsPaneConsts"
import { solutionHelpTooltipView } from "./SolutionHelpTooltips"


const SolutionHelpView = memo(({
    hiddenOutput,
    memorySubTooltip,
    spineBWSubTootlip,
    numberOfBuckets,
    numberOfBucketsTooltip,
    numberOfFilesAndDirsTitle,
    numberOfFilesAndDirsSubTooltip,
    openPerformanceSubTooltip,
    option,
    setOpenPerformanceSubTooltip
  }) => (
    <div>
      {_.map(serversEnclosuresHelpValues, (key, displayName) => {
        const value = _.get(option, key)
        if (value !== 'N/A' && !_.includes(hiddenOutput, displayName)) {
          if (displayName === 'Memory') {
            return solutionHelpTooltipView({
              option,
              openPerformanceSubTooltip,
              setOpenPerformanceSubTooltip,
              tooltip: memorySubTooltip,
              yOffset: -426,
              displayName: 'Memory',
              value: 'memory'
            })
          } else if (displayName === 'Spine BW (Gbits/sec)') {
            return solutionHelpTooltipView({
              option,
              openPerformanceSubTooltip,
              setOpenPerformanceSubTooltip,
              tooltip: spineBWSubTootlip,
              yOffset: -84,
              displayName: 'Spine BW (Gbits/sec)',
              value: 'spine_bw'
            })
          } else {
            return (
              <div key={_.kebabCase(displayName)}>
                <div><b>{displayName}</b></div>
                <div>{value && value.toLocaleString()}</div>
              </div>
            )
          }
        }
      })}
      { !_.includes(hiddenOutput, numberOfFilesAndDirsTitle) &&
        <div
          key={_.kebabCase(numberOfFilesAndDirsTitle)}
          className={classNames('files-and-dirs', {'selected': openPerformanceSubTooltip === numberOfFilesAndDirsTitle})}
        >
          <div><b>{numberOfFilesAndDirsTitle}</b></div>
          <div>
            <TooltipHolder
              content={numberOfFilesAndDirsSubTooltip}
              holder={<Info/>}
              holderClassName="help-icon"
              placement="bottomLeft"
              align={{offset: [36, -426]}}
              className={classNames("big sub-tooltip")}
              onClick={() => setOpenPerformanceSubTooltip(openPerformanceSubTooltip === numberOfFilesAndDirsTitle ? '' :
                numberOfFilesAndDirsTitle)}
            />
          </div>
        </div>
      }
      <div key="number-of-buckets" className={classNames("number-of-buckets", {'selected': openPerformanceSubTooltip === 'number-of-buckets'})}>
        <div><b>Number of Buckets</b></div>
        <div className="tooltipValue">
          <div>{_.min(_.values(numberOfBuckets))}</div>
          {!_.includes(_.keys(numberOfBuckets), "error") &&
            <TooltipHolder
              content={numberOfBucketsTooltip}
              holder={<Info/>}
              holderClassName="help-icon"
              placement="bottomLeft"
              align={{offset: [36, -181]}}
              className={classNames("sub-tooltip")}
              onClick={() => setOpenPerformanceSubTooltip(openPerformanceSubTooltip === 'number-of-buckets' ? '' : 'number-of-buckets')}
            />
          }
        </div>
      </div>
    </div>
))

export default SolutionHelpView