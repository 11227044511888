import _ from 'lodash'
import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './Input.scss'


/**
 * Primary UI component for user interaction
 */
const Input = ({ size, value, onChange, isNumber, isTag, min, max, className, ...props }) => {
  const [val, setVal] = useState(value)

  const isPositiveFloat = s => {
    return !isNaN(s) && Number(s) >= 0;
  }
  
  const handleChange = (e) => {
    if (_.isFunction(onChange) && (!isNumber || isPositiveFloat(e.target.value))) {
      onChange(e.target.value)
    }
  }

  const debouncedHandleChange = _.debounce((e) => handleChange(e), 500)

  return (
    <input
      type={isNumber ? "number" : "text" }
      min={min}
      max={max}
      className={classNames('input', size, className, {'tag': isTag})}
      value={val}
      onChange={(e) => {
        if (!(isNumber && e.target.value === '')) {
          setVal(e.target.value)
          debouncedHandleChange(e)
        }
      }}
      {...props}
    />
  );
};

Input.propTypes = {
  /**
   * If input is a number or not 
   */
  isNumber: PropTypes.bool,
  /**
   * If input is a tag or not
   */
  isTag: PropTypes.bool,
  /**
   * Minimum allowed value if input is a number
   */
  min: PropTypes.number,
  /**
   * Maximum allowed value if input is a number
   */
  max: PropTypes.number,
  /**
   * Input size
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * Optional click handler
   */
  onChange: PropTypes.func,
};

Input.defaultProps = {
  size: undefined,
  onChange: undefined,
  isNumber: false,
  max: undefined,
};

export default Input