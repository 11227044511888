import React, { memo } from 'react'
import classNames from "classnames"
import { Input } from '/src/storybook/components/Input'
import { TooltipHolder } from '/src/storybook/components/TooltipHolder'
import { Radio } from '/src/storybook/components/Radio'
import { RadioInput } from '/src/storybook/composedComponents/RadioInput'
import { useDispatch, useSelector } from 'react-redux'
import {
  requirementsSelector,
  setRequirement
} from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'
import './Performance.scss'


const Performance = memo(({handlerWithResultsScroll}) => {
  const { activeTab, read_part, performance_type, iops, bw, ioSize } = useSelector(requirementsSelector)
  const isfirstTab = activeTab === "BY REQUIREMENT"
  const dispatch = useDispatch()
  const help = (
    <div>
      <div><b>Performance</b></div>
      <br/>
      <div>This section is optional and lets you specify your application performance requirements. Performance can be specified in either IOPS (I/O operations per seconds) or bandwidth (GBytes per second). Performance calculations rely on benchmarks of Weka systems.</div>
      <br/>
      <div><b>R/W:</b> Specify the percentage of IO operations that are read operations vs. the total number of operations.</div>
      <br/>
      { isfirstTab &&
        <>
          <div><b>IOPS:</b> Specify the number of IO operations in 1000s per second required for the whole cluster. This option will be hidden if bandwidth performance is chosen.</div>
          <br/>
          <div><b>Bandwidth:</b> Specify the Gigabytes/second required for the whole cluster. This option will be hidden if IOPS performance is chosen.</div>
        </>
      }
    </div>
  )
  const radioInputItems = [
    {leftLabel: 'BW', rightLabel: 'GB/sec', radioValue: 'bw', value: bw, onInputChange: handlerWithResultsScroll(
      newBW => dispatch(setRequirement({bw: parseInt(newBW)})))
    },
    {leftLabel: "IOP's", rightLabel: 'M', radioValue: 'iops', value: iops, onInputChange: handlerWithResultsScroll(
      newIOPs => dispatch(setRequirement({iops: parseFloat(newIOPs)})))
    },
  ]
  const ioSizeInput = [
    {leftLabel: "IO Size", radioValue: 'iops', value: ioSize, onInputChange: handlerWithResultsScroll(
      newIOSize => dispatch(setRequirement({ioSize: parseInt(newIOSize)})))
    }
  ]
  const ioSizeUnit = (
    <Radio name="io-size-units" selected={"KB"} disabled={performance_type !== "iops"} items={[
      {label: "KB", value: "KB"},
      {label: "MB", value: "MB"}
    ]} onSelected={handlerWithResultsScroll(newIOSizeUnit =>
      dispatch(setRequirement({ioSizeUnit: newIOSizeUnit}))
    )}/>
  )
  return (
    <div className="performance">
      <div className="requirement-box-title">
        Performance
        <TooltipHolder content={help} className='performance'/>
      </div>
      <div className="requirement-row rw">
        <div>
          <b>R/W</b>
        </div>
        <Input
          size="small"
          value={read_part}
          isNumber
          max={100}
          onChange={handlerWithResultsScroll(newReadWrite =>
            dispatch(setRequirement({read_part: parseInt(newReadWrite)}))
          )}
        />
        <b>%</b>
      </div>
      { isfirstTab &&
        <>
          <RadioInput
            name="performance-type"
            items={radioInputItems}
            selected={performance_type}
            onSelected={handlerWithResultsScroll(newPerformanceType =>
              dispatch(setRequirement({performance_type: newPerformanceType}))
            )}
          />
          <div className={classNames("io-size", {'disabled': performance_type !== "iops"})}>
            <RadioInput
              key={`${performance_type}-io-size`}
              name="io-size"
              items={ioSizeInput}
              selected={performance_type}
              onSelected={()=>{}}
            />
            { ioSizeUnit }
          </div>
        </>
      }
      { !isfirstTab && (
        <div className="requirement-row io-size-by-sizing">
          <div>
            <b>IO Size</b>
          </div>
          <Input
            size="small"
            value={ioSize}
            isNumber
            max={100}
            onChange={handlerWithResultsScroll(
              newIOSize => dispatch(setRequirement({ioSize: parseInt(newIOSize)}))
            )}
          />
          { ioSizeUnit }
        </div>
      )}
    </div>
  )
})

export default Performance