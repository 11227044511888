import React, { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { Dropdown } from '/src/storybook/components/Dropdown'
import classNames from 'classnames'
import API_ENDPOINTS from '/src/app/apiEndpoints'
import { callAPI } from '/src/utils/apiUtils'
import { Button } from '/src/storybook/components/Button'
import { Loader } from '/src/storybook/components/Loader'
import './NotionDataCopy.scss'


const NotionDataCopy = memo(() => {

  const [ isLoading, setIsLoading ] = useState(false)
  const [ errors, setErrors ] = useState()
  const [ fromTo, setFromTo ] = useState(['Staging', 'Staging'])
  const environments = _.map(['Staging', 'Hotfix', 'Production'], value => ({value, label: value}))

  return (
    <div className="notion-data-copy">
      <div className='action-row'>
        Copy notion tables data from
        <Dropdown
          key={'from'}
          className="from"
          options={environments}
          selected={_.get(fromTo, 0)}
          managedState={false}
          onChange={({value}) => setFromTo([value, _.get(fromTo, 1)])}
        />
        to
        <Dropdown
          key={'to'}
          className="to"
          options={environments}
          selected={_.get(fromTo, 1)}
          managedState={false}
          onChange={({value}) => setFromTo([_.get(fromTo, 0), value])}
        />
        <Button label='Go' onClick={() => {
          setErrors([])
          setIsLoading(true)
          callAPI({
            endpoint: API_ENDPOINTS.POST_COPY_NOTION_DATA,
            payload: {fromTo},
            callback: ({errors}) => {
              if (errors) {
                setErrors(JSON.parse(errors))
              } else {
                setErrors([])
              }
              setIsLoading(false)
            }
          })
        }}/>
      </div>
      <div>* Be patient. It can take a few minutes</div>
      {isLoading && (<Loader/>)}
      {errors && _.isEmpty(errors) && !isLoading && (
        <div className='status'>Copy was successful!</div>
      )}
      <ul className="errors">
        {_.map(errors, error => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </div>
  )
})

export default NotionDataCopy
