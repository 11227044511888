import React, { memo } from 'react'
import { useDispatch } from "react-redux"
import _ from "lodash"

import { Input } from '/src/storybook/components/Input'
import { setCustomRaFields } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const FrontendCores = memo(({ allCustomizedFields, handlerWithResultsScroll }) => {
  const dispatch = useDispatch()
  const frontendCores = _.get(allCustomizedFields, 'frontend_cores')

  return (
    <div className="requirement-row">
      <b>FE Cores</b>
      <Input
        key={frontendCores}
        className="frontend-cores"
        size="medium"
        value={frontendCores}
        isNumber
        onChange={handlerWithResultsScroll(newFrontendCores => dispatch(setCustomRaFields({
          ...allCustomizedFields, frontend_cores: newFrontendCores
        })))}
      />
    </div>
  )
})

export default FrontendCores
