import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { requirementsSelector } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'
import BySizingTabView from "./BySizingTab.view"


const BySizingTab = memo(({exposeCustomRA, hotAndWarm, setHotAndWarm, hiddenRequirements, handlerWithResultsScroll}) => {
  const { vendors, vendorOptions, vendor, ra, activeTab, network, warmCostAndSpace } = useSelector(requirementsSelector)
  const vOptions = vendors ? vendors.map((v) => ({value: v[0], label: v[0]})) : []

  const raOptions = _.map(vendorOptions, o => {
    const value = _.get(o, 'ra')
    return {value, label: value}
  })

  let comesInBundle = false
  if (ra) {
    const raParams = _.find(vendorOptions, o => _.get(o, 'ra') === ra)
    comesInBundle = _.get(raParams, 'comes_in_bundle')
  }

  const props = {
    handlerWithResultsScroll,
    vOptions,
    vendor,
    raOptions,
    ra,
    hotAndWarm,
    setHotAndWarm,
    exposeCustomRA,
    comesInBundle,
    hiddenRequirements,
    network,
    activeTab,
    warmCostAndSpace
  }

  return <BySizingTabView {...props}/>
})

export default BySizingTab
