import _ from "lodash"
import React, { memo } from 'react'
import {useDispatch} from "react-redux"
import { Input } from '/src/storybook/components/Input'
import { setCustomRaFields } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const CPUClock = memo(({handlerWithResultsScroll, allCustomizedFields}) => {
  const dispatch = useDispatch()
  const cpu_clock = _.get(allCustomizedFields, 'cpu_clock', '').toString()

  return (
    <div className="requirement-row">
      <b>CPU clock</b>
      <Input
        key={cpu_clock}
        className="cpu-clock"
        size="medium"
        step="0.1"
        value={cpu_clock}
        isNumber
        onChange={handlerWithResultsScroll(newCPUClock => dispatch(setCustomRaFields({
          ...allCustomizedFields, cpu_clock: newCPUClock
        })))}
      />
    </div>
  )
})

export default CPUClock