import React from 'react'
import mixpanel from 'mixpanel-browser'
import _ from 'lodash'
import { Button } from '../../components/Button'
import { saveBOM } from './BOMModal.module'
import '../../styles/modal.scss'


const BOMModalFooter = ({ BOMModalData, BOMFormData, user, callAPI, API_ENDPOINTS, mixpanelID, reset }) => {
  const saveBOMDisabled = _.size(BOMFormData) - 1 < _.size(_.get(BOMModalData, ['bom_dialog', 'config dialog'])) || _.includes(_.values(BOMFormData), '')
  const hostname = window.location.hostname
  return (
    <div className="modal-footer">
      <div className="buttons">
        <Button secondary onClick={() => reset()} label='Cancel'/>
        <Button
          onClick={() => {
            if (callAPI) { // Only for storybook
              saveBOM({
                option: BOMModalData,
                BOMFormData,
                hostname,
                user,
                callAPI,
                API_ENDPOINTS
              })
            }
            if (mixpanelID) {
              mixpanel.track('BOM created', {
                user: _.get(user, 'email'),
                hostname,
                ...BOMFormData
              })
            }
            reset()
          }}
          disabled={saveBOMDisabled}
          disabledTooltip='Please fill all the fields'
          label='Save BOM'
        />
      </div>
    </div>
  )
}

export default BOMModalFooter
