import React, { memo } from 'react'

export default memo(() => (
  <svg width="678px" height="562px" viewBox="0 0 678 562" version="1.1">
    <defs>
        <path d="M361.71745,84.0160934 C368.073939,90.4007168 378.968883,85.8915765 378.968883,76.8599947 L378.968883,10.3268989 C378.968883,4.71375085 374.417159,0.178008007 368.817078,0.20449418 L302.836979,0.523841772 C293.866233,0.563745668 289.394131,11.4309067 295.73735,17.8022287 L361.71745,84.0160934 Z" id="path-1"></path>
        <filter x="-2.1%" y="-2.1%" width="104.2%" height="104.2%" filterUnits="objectBoundingBox" id="filter-2">
            <feGaussianBlur stdDeviation="1" in="SourceAlpha" result="shadowBlurInner1"></feGaussianBlur>
            <feOffset dx="0" dy="1" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
            <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
        </filter>
        <path d="M17.2779746,3.47406981 C10.9214847,-2.93715614 0,1.57198411 0,10.6168672 L0,64.2077995 C0,78.9190358 5.81240619,93.0450149 16.1499962,103.473233 L183.966637,272.798765 C190.323127,279.209991 201.244612,274.687549 201.244612,265.642666 L201.244612,212.091638 C201.244612,197.380402 195.445476,183.267724 185.094615,172.826204 L17.2779746,3.47406981 Z" id="path-3"></path>
        <filter x="-0.9%" y="-0.7%" width="101.8%" height="101.3%" filterUnits="objectBoundingBox" id="filter-4">
            <feGaussianBlur stdDeviation="1" in="SourceAlpha" result="shadowBlurInner1"></feGaussianBlur>
            <feOffset dx="0" dy="1" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
            <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
        </filter>
        <path d="M195.449457,3.47406981 C189.092967,-2.93715614 178.171482,1.57198411 178.171482,10.6168672 L178.171482,64.2077995 C178.171482,78.9190358 183.983888,93.0450149 194.334749,103.473233 L362.151389,272.798765 C368.507879,279.209991 379.416094,274.687549 379.416094,265.642666 L379.416094,212.091638 C379.416094,197.380402 373.616958,183.267724 363.266098,172.826204 L195.449457,3.47406981 Z" id="path-5"></path>
        <filter x="-0.9%" y="-0.7%" width="101.8%" height="101.3%" filterUnits="objectBoundingBox" id="filter-6">
            <feGaussianBlur stdDeviation="1" in="SourceAlpha" result="shadowBlurInner1"></feGaussianBlur>
            <feOffset dx="0" dy="1" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
            <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="6" transform="translate(-621.000000, -325.000000)">
            <g transform="translate(621.156489, 325.000000)">
                <g opacity="0.072101" transform="translate(0.843511, 0.000000)" fill="#000000">
                    <path d="M103.526469,43.2911167 C100.558078,44.6897888 97.4942957,45.2956548 94.3321179,45.1049609 C91.1706913,44.9157685 88.0821225,44.0020896 85.0649092,42.3684285 L93.297838,32.4343279 C95.0922424,33.0034065 96.8430824,33.0236771 98.5496068,32.494389 C100.256131,31.9666024 101.732064,30.9500688 102.978909,29.4470406 C104.701958,27.3659246 105.31862,25.0438135 104.82514,22.479206 C104.333162,19.9138476 102.791882,17.5594537 100.199047,15.4122706 C97.6062112,13.2665889 94.9961002,12.1824867 92.3672113,12.1644683 C89.7390736,12.1456993 87.5631047,13.1757466 85.8385536,15.255361 C84.5924603,16.75914 83.8766515,18.4048133 83.6903759,20.1931316 C83.5041004,21.9799483 83.8571226,23.7021994 84.7516959,25.3591341 L76.5187671,35.2932347 C73.1860872,31.2383619 71.5306383,26.8058551 71.5507343,21.9957144 C71.5719494,17.1848228 73.3979005,12.5878989 77.0302738,8.20494246 C79.8138915,4.84527648 82.9738159,2.51265477 86.5115492,1.20707731 C90.0500336,-0.0992509143 93.6936735,-0.344750528 97.4447223,0.469076939 C101.195771,1.28215364 104.753033,3.08023186 108.112753,5.86256082 C111.474727,8.64564054 113.903069,11.8026004 115.401535,15.3356927 C116.899251,18.8680343 117.335646,22.4927197 116.709971,26.209749 C116.085798,29.9267783 114.381526,33.465126 111.597158,36.824792 C109.18534,39.7355015 106.494109,41.890943 103.526469,43.2911167" id="Fill-1"></path>
                    <path d="M77.1986729,60.2037878 C76.3784596,57.6384294 74.4683841,55.4649696 71.4684463,53.6811558 C68.5383619,51.9408864 65.7412242,51.3177528 63.0777843,51.8140074 C60.4143443,52.3087604 58.2864466,53.8966249 56.6933399,56.5745978 C55.1220155,59.2172848 54.7547222,61.8254366 55.5922111,64.4020564 C56.430451,66.9764239 58.3127355,69.1348686 61.243571,70.8758888 C64.2435087,72.6589517 67.0616775,73.3053589 69.6980775,72.8188643 C72.3329752,72.3293666 74.4466018,70.7467575 76.0389574,68.0687846 C77.632064,65.390061 78.0188862,62.7683954 77.1986729,60.2037878 M77.4878507,82.1576096 C74.0740507,84.1178527 70.3800864,85.0638145 66.4067086,84.9984981 C62.4325798,84.9324309 58.5883932,83.7957752 54.8718955,81.5885309 C51.1561489,79.3805359 48.3259623,76.5396474 46.3798334,73.0643639 C44.4337046,69.5875789 43.5060824,65.8840633 43.5969668,61.9500633 C43.6878513,58.0175649 44.7844735,54.283268 46.8875845,50.7471727 C48.9884423,47.2125789 51.75178,44.4557758 55.1760955,42.4805175 C58.5996599,40.5022561 62.3033887,39.5382759 66.2887842,39.585574 C70.2726775,39.6336229 74.122873,40.7612694 77.8401218,42.9685137 C81.5543662,45.1765087 84.3838017,48.0181479 86.3306816,51.4934315 C88.2775616,54.968715 89.2006771,58.683492 89.0977749,62.6347595 C88.9978772,66.5852762 87.8952461,70.3278315 85.7928861,73.8631761 C83.6687439,77.4338066 80.9001484,80.198868 77.4878507,82.1576096" id="Fill-3"></path>
                    <polygon id="Fill-5" points="16.5097975 116.980266 21.0570241 105.577672 49.9327391 99.6383833 27.0568995 90.5271193 31.603375 79.1230241 72.5479432 95.432607 68.0014677 106.835952 38.8358238 112.658872 62.0000901 121.886504 57.4543657 133.289849"></polygon>
                    <polygon id="Fill-7" points="49.2094943 166.866463 39.7162011 164.487294 44.022321 147.321841 36.4022989 145.41265 33.1890457 158.225928 24.2388058 155.983398 27.4528102 143.17012 10.7030327 138.9711 13.6902015 127.063994 56.5035341 137.793153"></polygon>
                    <polygon id="Fill-9" points="46.9635219 182.572357 3.37954928 175.60227 5.32117146 163.480445 48.9043929 170.451283"></polygon>
                    <path d="M14.1779731,210.806615 C16.0151908,212.986831 18.7191908,214.174539 22.2884707,214.368236 C25.8149373,214.560431 28.6210884,213.672278 30.7054217,211.706029 C32.7890039,209.739029 33.9306928,206.952196 34.1274839,203.343276 L34.3543194,199.174287 L11.9441687,197.954297 L11.7173331,202.124037 C11.520542,205.732205 12.3400042,208.626398 14.1779731,210.806615 M10.3427998,223.329347 C7.10325766,221.279763 4.6223377,218.48317 2.90154217,214.935813 C1.18074665,211.388456 0.446911104,207.291541 0.700035544,202.646568 L1.65619997,185.100478 L45.729897,187.499167 L44.7729815,205.046008 C44.519857,209.69098 43.3744126,213.696303 41.3404037,217.058972 C39.3041416,220.421641 36.5753549,222.935947 33.1562972,224.601891 C29.7349862,226.266334 25.887044,226.983313 21.6132219,226.749825 C17.3386486,226.517839 13.5815909,225.37743 10.3427998,223.329347" id="Fill-11"></path>
                    <polygon id="Fill-13" points="35.1621394 244.240509 27.8876284 244.776554 28.9384328 259.008024 19.6742286 259.691219 18.6234241 245.459749 10.665402 246.046846 11.8536598 262.14171 2.09297108 262.862443 0.000375555549 234.52488 44.0192415 231.277829 46.1110859 259.615391 36.349646 260.334623"></polygon>
                    <polygon id="Fill-15" points="11.2864958 311.933912 8.79205585 299.914191 29.6106022 279.049727 5.49843368 284.050561 3.00324262 272.03084 46.159833 263.080239 48.6550241 275.099961 27.5315266 296.027489 51.9486462 290.96359 54.4438373 302.983311"></polygon>
                    <polygon id="Fill-17" points="67.1730673 338.502376 57.9516763 341.777956 53.9640275 330.563804 21.5956459 342.059492 17.5043437 330.55029 49.8727254 319.056103 45.9278899 307.956818 55.1500319 304.681988"></polygon>
                    <polygon id="Fill-19" points="73.7658699 351.758078 33.6963461 370.260641 28.5467284 359.11631 68.6155011 340.613747"></polygon>
                    <path d="M64.5559709,391.947341 L74.4195618,380.51772 L59.6534688,383.769276 L64.5559709,391.947341 Z M58.2128377,399.307825 L50.1676867,385.891684 L42.6092557,387.586157 L35.9739403,376.522908 L81.9734862,367.783272 L89.2487483,379.916359 L59.817211,416.290092 L53.1526022,405.172038 L58.2128377,399.307825 Z" id="Fill-21"></path>
                    <polygon id="Fill-23" points="79.2935969 425.63199 87.7991789 436.465506 80.4923702 442.196833 64.4043216 421.706249 99.1274365 394.470056 106.709152 404.126374"></polygon>
                    <path d="M93.9783446,451.949774 C93.1100602,453.248595 92.108078,454.416032 90.972398,455.452085 C88.6709936,457.552721 86.1998381,458.68262 83.5596826,458.843283 C80.918776,459.001694 78.4078116,457.777199 76.0267894,455.1713 L79.2948738,452.187016 C80.0782826,453.04664 80.837656,453.506858 81.5699893,453.569922 C82.3023226,453.631484 83.2074115,453.172768 84.2807492,452.193022 L84.7877492,451.731302 L81.4242737,448.049559 L89.3890558,440.780668 L95.9470068,447.959467 C95.5015979,449.320601 94.8458779,450.651705 93.9783446,451.949774" id="Fill-25"></path>
                    <path d="M142.405932,461.604891 C144.509794,459.139384 144.201839,456.747452 141.482817,454.428344 L137.924052,451.395261 L131.572657,458.839079 L135.129919,461.871412 C137.849692,464.19052 140.274279,464.101179 142.405932,461.604891 L142.405932,461.604891 Z M125.302381,466.187551 L115.713697,477.424226 L106.369875,469.457876 L135.012746,435.891247 L148.957874,447.779584 C153.005612,451.230843 155.225896,454.885559 155.622483,458.742981 C156.016816,462.602656 154.757954,466.237852 151.844394,469.651573 C150.037221,471.768725 147.944625,473.261243 145.567359,474.128375 C143.187839,474.994756 140.64683,475.10737 137.943581,474.46772 C135.23883,473.827319 132.559617,472.374591 129.902937,470.110289 L125.302381,466.187551 Z" id="Fill-27"></path>
                    <polygon id="Fill-29" points="154.651146 495.624531 166.074795 503.322858 160.883115 511.021186 139.275151 496.460131 163.949151 459.879937 174.132715 466.741914"></polygon>
                    <polygon id="Fill-31" points="194.25761 490.279772 190.835548 496.719069 203.443699 503.412124 199.087254 511.611961 186.479103 504.918906 182.735566 511.963319 196.996161 519.533266 192.404619 528.174552 167.297979 514.84625 188.000855 475.882383 213.108245 489.209183 208.516703 497.850469"></polygon>
                    <path d="M229.710129,525.399956 L230.280222,510.31712 L220.772658,522.069569 L229.710129,525.399956 Z M229.350347,535.108827 L214.687156,529.64327 L209.840236,535.684663 L197.747347,531.178581 L228.23044,495.653961 L241.491307,500.595485 L241.208138,547.374799 L229.058165,542.846945 L229.350347,535.108827 Z" id="Fill-33"></path>
                    <path d="M280.057331,549.835651 C278.40714,551.510604 276.276989,552.654016 273.671385,553.264387 C271.065029,553.875508 268.119923,553.814697 264.833811,553.081951 C259.84418,551.970822 256.004501,549.859675 253.313269,546.747761 C250.622038,543.636598 249.626065,539.840738 250.328354,535.356429 L263.046167,538.190561 C262.832852,539.718364 263.049923,540.936853 263.698883,541.847529 C264.347091,542.758955 265.259691,543.344551 266.436683,543.607318 C267.491243,543.842307 268.370794,543.762726 269.076838,543.367074 C269.782131,542.970671 270.244816,542.285975 270.461136,541.312235 C270.750314,540.015666 270.386025,538.880512 269.36902,537.908274 C268.349011,536.936786 266.65826,535.74082 264.293011,534.319625 C261.782798,532.781311 259.745034,531.369125 258.179718,530.083067 C256.614403,528.798511 255.390843,527.122807 254.511292,525.052953 C253.631741,522.9846 253.503301,520.550625 254.128225,517.753281 C254.724607,515.076809 255.927887,512.930377 257.736563,511.311731 C259.544487,509.693085 261.75726,508.653278 264.372629,508.19306 C266.987998,507.733593 269.815931,507.841703 272.858682,508.521144 C277.807002,509.623264 281.491953,511.667593 283.912784,514.65488 C286.333616,517.642919 287.21542,521.275862 286.558949,525.555213 L273.720958,522.694805 C273.856158,521.320157 273.662371,520.233803 273.138847,519.436492 C272.61382,518.638431 271.804122,518.11665 270.708251,517.873403 C269.77462,517.664691 268.99046,517.745773 268.35502,518.114398 C267.718078,518.483023 267.292949,519.154956 267.075878,520.128696 C266.803976,521.344182 267.169767,522.426032 268.171749,523.372744 C269.173731,524.320958 270.817914,525.495152 273.102042,526.896828 C275.602491,528.477184 277.641007,529.931413 279.219091,531.261766 C280.794922,532.592118 282.033505,534.294099 282.934087,536.366956 C283.833167,538.441315 283.968367,540.897813 283.334429,543.734948 C282.800389,546.127631 281.708273,548.160698 280.057331,549.835651" id="Fill-35"></path>
                    <polygon id="Fill-37" points="304.789767 524.426291 304.038656 531.677914 318.238411 533.14941 317.281496 542.38455 303.08099 540.913054 302.257772 548.84637 318.31878 550.510812 317.309287 560.241456 289.032208 557.313479 293.58319 513.42986 321.860269 516.358588 320.851527 526.089982"></polygon>
                    <path d="M363.037532,545.682278 C364.991923,543.605666 365.858705,540.785049 365.638629,537.218923 C365.420807,533.694089 364.21302,531.011611 362.018274,529.169238 C359.822025,527.328366 356.919732,526.519043 353.310643,526.741269 L349.141976,526.998781 L350.524772,549.388796 L354.693438,549.131285 C358.301776,548.908308 361.083141,547.760391 363.037532,545.682278 M375.925847,548.039675 C374.264389,551.493186 371.772203,554.28002 368.446283,556.397923 C365.120363,558.518829 361.135718,559.721552 356.490096,560.008344 L338.94339,561.091695 L336.223616,517.057924 L353.770323,515.974572 C358.415194,515.688531 362.527527,516.360465 366.105069,517.991873 C369.683363,519.622531 372.497776,522.04074 374.548309,525.242746 C376.598842,528.447004 377.757056,532.184304 378.019945,536.453895 C378.284336,540.724987 377.585802,544.586914 375.925847,548.039675" id="Fill-39"></path>
                    <path d="M396.095208,524.592735 C394.777759,526.942624 394.490084,529.821051 395.232932,533.230267 C395.957755,536.559152 397.409652,539.028412 399.589377,540.636548 C401.76835,542.246935 404.381466,542.719165 407.427972,542.056241 C410.432417,541.402326 412.591861,539.89104 413.904803,537.522381 C415.217745,535.153723 415.512181,532.303825 414.787359,528.975691 C414.043759,525.565724 412.595617,523.065682 410.44143,521.471812 C408.285741,519.879443 405.686897,519.413218 402.639639,520.075392 C399.593883,520.738316 397.412657,522.244347 396.095208,524.592735 M411.959425,509.406294 C415.721741,510.564722 418.933492,512.619561 421.596181,515.567809 C424.25887,518.518309 426.05027,522.103954 426.96963,526.323995 C427.88899,530.547039 427.741772,534.554614 426.525723,538.345969 C425.310425,542.141077 423.232852,545.345335 420.293754,547.960995 C417.353905,550.578156 413.874008,552.323681 409.854061,553.19832 C405.833363,554.072959 401.932092,553.933317 398.150248,552.779394 C394.368404,551.625471 391.136372,549.576637 388.453404,546.630642 C385.770435,543.686899 383.96927,540.103506 383.050661,535.881212 C382.129799,531.660421 382.278519,527.652846 383.493817,523.85999 C384.709115,520.066383 386.795701,516.859872 389.757332,514.238207 C392.715208,511.618043 396.20487,509.868764 400.226319,508.994876 C404.286826,508.110476 408.198612,508.247866 411.959425,509.406294" id="Fill-41"></path>
                    <polygon id="Fill-43" points="479.034323 530.265277 467.586638 534.711298 443.559345 517.637438 452.47879 540.579264 441.030354 545.026036 425.06699 503.961533 436.514674 499.514762 460.653883 516.880668 451.623274 493.646795 463.070207 489.201525"></polygon>
                    <path d="M475.467972,480.409861 C476.853772,483.108105 477.265381,485.662953 476.704301,488.074404 C476.144723,490.487358 474.478759,492.404057 471.705656,493.826002 L469.684416,489.88975 C471.680119,488.86646 472.006101,487.042105 470.658608,484.417436 L470.259768,483.641898 L466.488439,485.575864 L462.674297,478.147061 L470.604528,474.077923 C472.651305,475.971349 474.271452,478.080994 475.467972,480.409861" id="Fill-45"></path>
                    <polygon id="Fill-47" points="508.728899 462.109254 513.937854 470.391675 503.856441 476.726616 522.136983 505.794672 511.79193 512.29403 493.510637 483.225224 483.536633 489.492596 478.326926 481.209424"></polygon>
                    <path d="M567.432137,462.406181 C567.810697,464.727541 567.477955,467.120224 566.432408,469.582728 C565.386861,472.046733 563.598466,474.387613 561.067973,476.606119 C557.224537,479.975545 553.253413,481.827678 549.151595,482.161768 C545.05128,482.49811 541.397124,481.059647 538.192133,477.84638 L547.986622,469.258398 C549.096013,470.333491 550.207657,470.877044 551.326062,470.890558 C552.443715,470.904822 553.456964,470.515176 554.362804,469.720118 C555.175506,469.007643 555.629928,468.250874 555.726822,467.447557 C555.823715,466.644991 555.542048,465.867951 554.884826,465.117938 C554.009031,464.118672 552.876355,463.743291 551.491306,463.990292 C550.105506,464.238794 548.139848,464.897214 545.597337,465.967802 C542.873057,467.084938 540.530342,467.895763 538.567689,468.399525 C536.605786,468.904789 534.529715,468.901035 532.339475,468.389765 C530.149235,467.878495 528.108467,466.54589 526.21792,464.390449 C524.409244,462.328852 523.384729,460.090827 523.145876,457.675621 C522.90552,455.261917 523.37196,452.86473 524.543693,450.481807 C525.716929,448.100385 527.47528,445.88188 529.817244,443.827791 C533.629133,440.486144 537.455293,438.720348 541.297226,438.529654 C545.137657,438.340462 548.592768,439.773669 551.661808,442.829276 L541.77268,451.499842 C540.743657,450.577905 539.75144,450.093662 538.797529,450.044863 C537.843618,449.998315 536.944538,450.343666 536.10104,451.083168 C535.382226,451.713809 534.984889,452.394001 534.907524,453.124494 C534.83016,453.855737 535.120089,454.59599 535.778062,455.346003 C536.600529,456.282955 537.68964,456.626054 539.046146,456.376801 C540.402653,456.126046 542.320991,455.493903 544.802662,454.47812 C547.553231,453.393267 549.932,452.607969 551.937466,452.119972 C553.942933,451.632727 556.047546,451.638733 558.252808,452.135738 C560.459573,452.634245 562.521373,453.975859 564.43971,456.161332 C566.057604,458.004456 567.053577,460.084821 567.432137,462.406181" id="Fill-49"></path>
                    <polygon id="Fill-51" points="571.914618 400.8077 605.180577 429.804433 597.149697 439.009542 583.710818 427.296133 573.877271 438.567343 587.3154 450.280752 579.24396 459.53316 545.978752 430.537178 554.050191 421.28402 566.500609 432.137805 576.335658 420.866595 563.883738 410.01281"></polygon>
                    <path d="M611.089042,391.268426 L596.449887,387.562658 L605.964963,399.309852 L611.089042,391.268426 Z M620.511731,393.646844 L612.106047,406.839258 L617.004793,412.838608 L610.072038,423.71867 L581.675532,386.505583 L589.277527,374.574452 L634.989398,384.615161 L628.023593,395.547026 L620.511731,393.646844 Z" id="Fill-53"></path>
                    <path d="M620.927546,356.506056 L623.419733,350.999207 C624.824311,347.895551 624.126528,345.71083 621.324133,344.442791 C619.999924,343.844432 618.783124,343.761097 617.672982,344.195039 C616.565093,344.628229 615.659253,345.620738 614.957715,347.171065 L612.464778,352.678664 L620.927546,356.506056 Z M647.438764,357.346911 L628.938897,358.761349 L628.424386,359.897254 L643.250568,366.600069 L638.187328,377.785692 L597.973591,359.59845 L605.965413,341.943499 C607.421818,338.725728 609.218475,336.244455 611.354635,334.496678 C613.490795,332.750402 615.801213,331.754139 618.282884,331.50939 C620.766057,331.264642 623.238715,331.698583 625.700106,332.811214 C628.502502,334.078503 630.583831,335.955411 631.945595,338.439687 C633.306608,340.926215 633.743755,343.813651 633.257035,347.104247 L653.015012,345.027635 L647.438764,357.346911 Z" id="Fill-55"></path>
                    <polygon id="Fill-57" points="626.478032 315.404691 633.438579 317.584908 637.708645 303.969064 646.571756 306.746137 642.30169 320.362732 649.915703 322.747907 654.746098 307.349 664.085414 310.274725 655.582085 337.387792 613.465032 324.191625 621.968361 297.077056 631.308427 300.003532"></polygon>
                    <polygon id="Fill-59" points="630.320867 250.056748 639.99668 251.537253 638.194764 263.302465 672.150244 268.496997 670.301008 280.569272 636.345529 275.37474 634.562391 287.016076 624.88808 285.537073"></polygon>
                    <polygon id="Fill-61" points="631.308878 208.80508 675.43966 209.60164 675.219584 221.813556 657.392713 221.491479 657.121562 236.443681 674.949184 236.765759 674.726855 249.039989 630.595323 248.242678 630.818403 235.969199 647.336087 236.267252 647.605736 221.314298 631.088051 221.016245"></polygon>
                    <polygon id="Fill-63" points="629.369509 191.571232 673.343309 187.761108 674.403877 199.991043 630.430078 203.800416"></polygon>
                    <path d="M661.098095,149.04169 C663.282326,149.914077 665.161606,151.433622 666.734432,153.597322 C668.307259,155.763274 669.392614,158.501308 669.989748,161.812174 C670.89709,166.841538 670.438912,171.198217 668.610708,174.882964 C666.784006,178.56771 663.666894,180.956639 659.260126,182.045997 L656.948206,169.228214 C658.439912,168.832562 659.480201,168.16138 660.068321,167.209412 C660.656441,166.259696 660.844219,165.192111 660.630152,164.006655 C660.437868,162.943574 660.023255,162.163531 659.38481,161.665024 C658.746366,161.169521 657.935166,161.008107 656.953463,161.186037 C655.645028,161.421777 654.739939,162.197316 654.238197,163.511152 C653.736455,164.826489 653.288041,166.849045 652.893708,169.578821 C652.446797,172.488029 651.934539,174.912244 651.35393,176.853718 C650.776326,178.79369 649.703739,180.569996 648.135419,182.182636 C646.568601,183.794525 644.373104,184.854603 641.551179,185.36362 C638.851686,185.850115 636.405317,185.573834 634.210571,184.534027 C632.018828,183.49497 630.202642,181.858306 628.765015,179.626287 C627.328891,177.395018 626.332917,174.746325 625.7801,171.679457 C624.880268,166.692887 625.339948,162.505879 627.156135,159.116934 C628.973073,155.728739 631.982775,153.508732 636.185242,152.455411 L638.518193,165.394817 C637.199242,165.801731 636.271619,166.40159 635.739082,167.193646 C635.207295,167.985701 635.039797,168.933915 635.238842,170.038288 C635.408593,170.979745 635.787904,171.671949 636.373019,172.114149 C636.959637,172.557099 637.744548,172.689233 638.72625,172.513555 C639.952815,172.29208 640.809082,171.53531 641.29505,170.244748 C641.780268,168.954186 642.226428,166.984934 642.635784,164.33624 C643.123255,161.420276 643.675321,158.978793 644.291984,157.009541 C644.907895,155.041039 645.997757,153.24146 647.56157,151.607799 C649.126135,149.975639 651.338157,148.901297 654.200641,148.385522 C656.614712,147.95083 658.913112,148.169303 661.098095,149.04169" id="Fill-65"></path>
                    <polygon id="Fill-67" points="615.328689 128.162586 657.390911 114.789989 661.114168 126.488383 619.051947 139.860229"></polygon>
                    <polygon id="Fill-69" points="638.187704 68.1235153 643.149544 79.3519319 627.173411 104.116612 649.699232 94.1712495 654.661072 105.400417 614.343682 123.201016 609.381091 111.972599 625.641895 87.0817913 602.831402 97.1525308 597.869562 85.9233634"></polygon>
                    <polygon id="Fill-71" points="579.661202 56.5709941 588.005295 51.4567942 597.259735 66.5441344 603.956642 62.4397112 597.047171 51.1775102 604.913557 46.3576096 611.822277 57.6190597 626.544055 48.5963859 632.963801 59.0612761 595.33614 82.1224738"></polygon>
                    <path d="M580.124788,34.6793356 C582.817521,34.7851932 585.513259,33.7333737 588.214254,31.5223757 C590.851405,29.3646818 592.391934,26.9494761 592.83509,24.2782602 C593.279748,21.6070442 592.513614,19.0657102 590.538192,16.6542584 C588.590561,14.2758401 586.262868,13.0408344 583.554361,12.946989 C580.846606,12.8546451 578.173401,13.8869447 575.537752,16.0453893 C572.835255,18.2563874 571.265432,20.6828545 570.823779,23.3255415 C570.383628,25.9674777 571.150512,28.4945471 573.125935,30.9052482 C575.099855,33.3159493 577.433557,34.5742287 580.124788,34.6793356 M559.381353,27.4547399 C558.704602,23.5792995 559.076402,19.7856923 560.493748,16.0754198 C561.913348,12.3643966 564.293619,9.13911729 567.639068,6.40258489 C570.982264,3.66455097 574.622148,1.9760842 578.553463,1.33493231 C582.487783,0.693029645 586.287654,1.0871804 589.95533,2.51513229 C593.623005,3.94383493 596.758894,6.24867841 599.366001,9.43041347 C601.972356,12.6121485 603.620294,16.1489946 604.310565,20.0409518 C605.000836,23.9336598 604.64481,27.7422822 603.237228,31.4698223 C601.831899,35.1966115 599.457636,38.4278969 596.11369,41.1651801 C592.768992,43.9024633 589.13061,45.59093 585.197041,46.2328327 C581.264975,46.8747353 577.457592,46.4745785 573.777148,45.029359 C570.095953,43.5856411 566.952553,41.2717885 564.345446,38.0893027 C561.713553,34.8760356 560.058104,31.3301803 559.381353,27.4547399" id="Fill-73"></path>
                </g>
                <g transform="translate(130.843511, 124.000000)" opacity="0.226225">
                    <g>
                        <g>
                            <use fill="#D8D8D8" fillRule="evenodd"></use>
                            <use fill="black" fillOpacity="1" filter="url(#filter-2)"></use>
                            <use stroke="#979797" strokeWidth="0.629999973"></use>
                        </g>
                        <g>
                            <use fill="#D8D8D8" fillRule="evenodd" ></use>
                            <use fill="black" fillOpacity="1" filter="url(#filter-4)"></use>
                            <use stroke="#979797" strokeWidth="0.629999973"></use>
                        </g>
                        <g>
                            <use fill="#D8D8D8" fillRule="evenodd"></use>
                            <use fill="black" fillOpacity="1" filter="url(#filter-6)"></use>
                            <use stroke="#979797" strokeWidth="0.629999973"></use>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
))