import React, { memo } from 'react'
import _ from 'lodash'
import { Radio } from '/src/storybook/components/Radio'
import { TooltipHolder } from '/src/storybook/components/TooltipHolder'
import { useDispatch, useSelector } from 'react-redux'
import { setRequirement, requirementsSelector } from '../RequirementsPane.module'
import './CustomerAndDeal.scss'


const CustomerAndDeal = memo(({ hiddenRequirements, handlerWithResultsScroll }) => {
  const { license, customer_type, license_type, feature_set, deployment_type } = useSelector(requirementsSelector)
  const dispatch = useDispatch()

  const featureSetHelp = (
    <div>
      <div><b>Feature Set:</b> Activating this option will show Weka planing based on short-term product improvement, as follows:</div>
      <ul>
        <li>At the momenet there are no relevant prouct improvements</li>
      </ul>
      Please note the following:
      <ul>
        <li>Consult with product management team for a roadmap of software features</li>
      </ul>
    </div>
  )
  const deploymentTypeHelp = (
    <div>
      At this moment there is no difference between POC and production, this can be updated in the future.
    </div>
  )

  return (
    <div className="requirement-box customer-and-deal">
      <div className="requirement-box-title">Customer and deal</div>
      <div className="requirement-row">
        <b className="for-radio">License</b>
        <Radio name="license" selected={license} items={[
          {label: "1Y", value: "1Y"},
          {label: "3Y", value: "3Y"},
          {label: "5Y", value: "5Y"}
        ]} onSelected={handlerWithResultsScroll(newLicense =>
          dispatch(setRequirement({license: newLicense}))
        )}/>
      </div>
      {!_.includes(hiddenRequirements, "customerType") &&
        <div className="requirement-row">
          <b className="for-radio">Customer Type</b>
          <Radio name="customer-type" selected={customer_type} items={[
            {label: "ENT", value: "Enterprise"},
            {label: "SLED", value: "SLED"},
            {label: "Federal", value: "Federal"}
          ]} onSelected={handlerWithResultsScroll(newCustomerType =>
            dispatch(setRequirement({customer_type: newCustomerType}))
          )}/>
        </div>
      }
      {!_.includes(hiddenRequirements, "licenseType") &&
        <div className="requirement-row">
          <b className="for-radio">License Type</b>
          <Radio name="license-type" selected={license_type} items={[
            {label: "DPO", value: "DPO"},
            {label: "DTO", value: "DTO"}
          ]} onSelected={handlerWithResultsScroll(newLicenseType =>
            dispatch(setRequirement({license_type: newLicenseType}))
          )}/>
        </div>
      }
      {!_.includes(hiddenRequirements, "features") &&
        <div className="requirement-row">
          <b className="for-radio">Feature Set</b>
          <Radio name="feature-set" selected={feature_set} items={[
            {label: "GA", value: "GA"},
            {label: "Future", value: "Future"}
          ]} onSelected={handlerWithResultsScroll(newFeatureSet =>
            dispatch(setRequirement({feature_set: newFeatureSet}))
          )}/>
          <TooltipHolder content={featureSetHelp}/>
        </div>
      }
      {!_.includes(hiddenRequirements, "deploymentType") &&
        <div className="requirement-row">
          <b className="for-radio">Deployment Type</b>
          <Radio name="deployment-type" selected={deployment_type} items={[
            {label: "Production", value: "production"},
            {label: "POC", value: "poc"}
          ]} onSelected={handlerWithResultsScroll(newDeploymentType =>
            dispatch(setRequirement({deployment_type: newDeploymentType}))
          )}/>
          <TooltipHolder content={deploymentTypeHelp}/>
        </div>
      }
    </div>
  )
})

export default CustomerAndDeal
