import _ from 'lodash'
import React, { useState } from "react";
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router' // react-router v4/v5
import ReactNotification from 'react-notifications-component'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/lib/integration/react'
import PrivateRoute from '/src/hooks/PrivateRoute'
import PrivateAdminRoute from '/src/hooks/PrivateAdminRoute'
import configureStore, { history } from './src/app/store'
import { NotionDatabaseValidation } from '/src/routes/NotionDatabaseValidation'
import { NotionDataCopy } from '/src/routes/NotionDataCopy'
import { Login } from '/src/routes/Login'
import { Logout } from '/src/routes/Logout'
import { SizerAdmin } from '/src/routes/SizerAdmin'
import { SizerCalculator } from '/src/routes/SizerCalculator'
import { Auth0Provider } from "@auth0/auth0-react"
import config from '/src/config'

// import { Export } from '/src/routes/SizerCalculator/ResultsPane/Export'
import './src/index.scss'

const { store, persistor } = configureStore(/* provide initial state if any */)
// TODO: Can be removed if this issue is fixed: https://github.com/teodosii/react-notifications-component/issues/132
// TODO: and react-notifications-component is upgraded to the latest version.
const ReactNotifications = _.get(ReactNotification, ["ReactNotifications", "default"], ReactNotification)
config.AUTH_CLIENT.start()

const App = () => (
  <Auth0Provider
    domain={config.VARTOPIA_AUTH0_DOMAIN}
    clientId={config.VARTOPIA_AUTH0_CLIENT_ID}
    authorizationParams={{redirect_uri: window.location.origin, install: "sizer"}}
  >
    <Provider store={store}>
      <ReactNotifications />
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <Switch>
            {/* TODO: use Public restricted route for login: https://www.linkedin.com/pulse/how-create-private-public-route-react-example-users-sreenath-k-s/ */}
            {/*<Route path="/export" component={Export} />*/}
            <Route exact path="/notion_database_validation" component={NotionDatabaseValidation} />
            <Route exact path="/notion_data_copy" component={NotionDataCopy} />
            <Route path="/logout" component={Logout} />
            <Route path="/login" component={Login} />
            <PrivateAdminRoute path="/admin" component={SizerAdmin} />
            <PrivateRoute path="/" component={SizerCalculator} />
          </Switch>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </Auth0Provider>
)

const rootElement = document.getElementById("root");
render(<App />, rootElement);