import React, {memo, useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'
import Copy from '/src/storybook/icons/Copy'
import PDFExport from '/src/storybook/icons/PDFExport'
import { labelModifiersSelector } from '/src/routes/Login'
import { STRINGS } from '/src/app/consts'
import { Dropdown } from '/src/storybook/components/Dropdown'
import { CopyToClipboard } from '/src/routes/SizerCalculator/ResultsPane/ResultsGrid/CopyToClipboard'
import { Export } from './Export'
import ResultsPaneView from "./ResultsPane.view"


const ResultsPane = memo(({
    calculating,
    results,
    showExactOption,
    selectedVendors,
    exportMode,
    setExportMode,
    isPartner,
    isWarm,
    read_part,
    hiddenOutput,
    ioSize,
    ioSizeUnit,
    user
  }) => {
  const labelModifiers = useSelector(labelModifiersSelector)
  const [ selectedForExport, setSelectedForExport ] = useState({})
  const [ exportNameModalIsOpen, setExportNameModalIsOpen ] = useState(false)
  const [ exportData, setExportData ] = useState({})

  useEffect(() => {
    setExportMode(_.size(selectedForExport) > 0)
  }, [selectedForExport])

  if (_.isEmpty(results)) {
    return null
  }
  const strings = { ...STRINGS, ...labelModifiers }
  const { VENDOR, REFERENCE_ARCHITECTURE_NAME, SERVERS_ENCLOSURES, NUMBER_OF_NVMES, NET_CAPACITY, IOPS, BW,
      PRICE, SPACE } = strings
  let headers = [VENDOR, REFERENCE_ARCHITECTURE_NAME, SERVERS_ENCLOSURES, NUMBER_OF_NVMES, NET_CAPACITY,
    `${ioSize}${ioSizeUnit} ${IOPS}`, BW, SPACE]
  if (!isPartner) {
    headers.push(PRICE)
  }
  const { options, exact_option, error, exact_option_error } = results
  const filteredOptions = _.filter(options, o => _.includes(selectedVendors, _.get(o, 'vendor')))
  const allOptions = showExactOption ? [exact_option] : filteredOptions
  const resetExport = () => {
    setExportMode(false)
    setSelectedForExport({})
    setExportData({})
  }
  const handlerExport = (selectedOption) => {
    if (_.get(selectedOption, 'value') === 'pdf') {
      setExportData({}) // Clearing data from previous export to be on the safe side
      setExportNameModalIsOpen(true)
    }
    if (_.get(selectedOption, 'value') === 'copy') {
      CopyToClipboard({ selectedForExport, isPartner, isWarm, readPart: read_part, headers })
      resetExport()
    }
  }
  if (error || (exact_option_error && showExactOption)) {
    return (
      <div className={classNames("results-pane", {calculating})}>
        <div className="error">
          {_.map((error || exact_option_error).split("\n"), (line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
      </div>
    )
  }
  const exportDropdown = (
    <Dropdown
      className="export-dropdown"
      isSearchable={false}
      isDisabled={!exportMode}
      filterOption={(option) => option.value !== "export"}
      options={[
        {value: 'export', label: 'Export'},
        {value: 'copy', label: (<div><Copy /><span>Copy</span></div>)},
        {value: 'pdf', label: (<div><PDFExport /><span>PDF</span></div>)}
      ]}
      onChange={handlerExport}
    />
  )
  const props = { calculating, allOptions, exportDropdown, exportMode, resetExport, isPartner, headers,
    selectedForExport, setSelectedForExport, exportNameModalIsOpen, setExportNameModalIsOpen, strings, setExportData,
    exportData, hiddenOutput, user }
  return <ResultsPaneView {...props} />
})

export default ResultsPane
