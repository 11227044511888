import _ from 'lodash'
import AbortController from "abort-controller"
import API_ENDPOINTS from '/src/app/apiEndpoints'

export const METHODS = { GET: 'GET', POST: 'POST', PUT: 'PUT', DELETE: 'DELETE'}
export let aborter = {}

const logedOutAfterBEVersionChanged = ({data, endpoint}) => {
  if (!_.includes([
        API_ENDPOINTS.GET_ALLOWED_SITES,
        API_ENDPOINTS.GET_NOTION_RAS_VALIDATION,
        API_ENDPOINTS.POST_COPY_NOTION_DATA
      ], endpoint) &&
      _.has(data, "backendVersion") &&
      _.get(data, "backendVersion") != localStorage.getItem("backendVersion")) {
    if (_.includes([API_ENDPOINTS.GET_REQUIREMENTS_DATA, API_ENDPOINTS.POST_IS_ADMIN], endpoint)) {
      // We are just logging in...
      localStorage.setItem('backendVersion', _.get(data, "backendVersion"))
    } else {
      localStorage.removeItem('persist:root')
      window.location = window.location.href
      return true
    }
  }
  return false
}

const fetchWithTimeout = async (resource, options) => {
  const { timeout } = options
  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)
  const response = await fetch(resource, {...options,
    signal: controller.signal
  })
  clearTimeout(id)
  return response
}

export const callAPI = ( { endpoint, payload, callback, id, errorHandler, timeout=90000 } ) => {
  const { path, method } = endpoint

  if (method === METHODS.GET) {
    const params = _.join(_.map(payload, (val, key) => {
      return `${key}=${val}`
    }), '&')
    fetchWithTimeout(`${path}?${params}`, {timeout})
      .then(res => res.json())
      .then(data => {
        if (!logedOutAfterBEVersionChanged({data, endpoint}) && callback) {
          callback(data)
        }
      })
      .catch(errorHandler ? errorHandler : err => console.log(err))
  }

  if (method === METHODS.POST) {
    if (_.get(aborter, path)) {
      _.get(aborter, path).abort()
      aborter[path] = null
    }
    aborter[path] = new AbortController()
    const signal = aborter[path].signal
    const requestOptions = {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
      signal
    }
    fetch(path, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (!logedOutAfterBEVersionChanged({data, endpoint}) && callback) {
          callback(data)
        }
      })
      .catch(err => { console.log(`Error calling ${path}: ${err}`) })
  }

  if (method === METHODS.PUT) {
    const requestOptions = { method, headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(payload) }
    // TODO: This is a dirty way to update some id with Rest API
    // apiEndpoints need to be able to get parameters in the path
    fetch(path + id, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (!logedOutAfterBEVersionChanged({data, endpoint}) && callback) {
          callback(data)
        }
      })
      .catch(err => { console.log(`Error calling ${path}: ${err}`) })
  }

  if (method === METHODS.DELETE) {
    const requestOptions = { method, headers: { 'Content-Type': 'application/json' }}
    // TODO: This is a dirty way to delete some id with Rest API
    // apiEndpoints need to be able to get parameters in the path
    fetch(path + id, requestOptions)
      .then(data => {
        if (callback) { callback() }
      })
      .catch(err => { console.log(`Error calling ${path}: ${err}`) })
  }
}
