import React from 'react'
import './Footer.scss'

/**
 * Primary UI component for user interaction
 */
const Footer = () => {
  return (
    <div className="footer">
      <div> 
        <span>© {new Date().getFullYear()} WekaIO, Inc. All Rights Reserved. </span>
        <a target="_blank" rel="noreferrer" href="https://www.weka.io/privacy-policy">
          Privacy Policy
        </a>
      </div>
      <div>
        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/weka-io">
          <svg xmlns="http://www.w3.org/2000/svg" width="25.53" height="26" viewBox="0 0 25.53 26">
            <path fill='#fff' fillRule="evenodd" d="M1223.78,1956.99a13.012,13.012,0,1,0,12.76,13.01A12.89,12.89,0,0,0,1223.78,1956.99Zm1.27,10.6v-0.04a0.052,0.052,0,0,0-.02.04h0.02Zm-5,8.54h-3.22v-9.99h3.22v9.99Zm-1.61-11.36h-0.02A1.834,1.834,0,1,1,1218.44,1964.77Zm13.22,11.36h-3.22v-5.35c0-1.34-.47-2.26-1.63-2.26a1.787,1.787,0,0,0-1.66,1.22,2.459,2.459,0,0,0-.1.81v5.58h-3.22s0.04-9.06,0-9.99h3.22v1.41a3.182,3.182,0,0,1,2.9-1.65c2.12,0,3.71,1.43,3.71,4.5v5.73Z" transform="translate(-1211 -1957)"/>
          </svg>
        </a>
        <a target="_blank" rel="noreferrer" href="https://twitter.com/wekaio">
          <svg xmlns="http://www.w3.org/2000/svg" width="25.54" height="26" viewBox="0 0 25.54 26">
            <path fill="#fff" fillRule="evenodd" d="M1264.85,1956.99a13.012,13.012,0,1,0,12.77,13.01A12.882,12.882,0,0,0,1264.85,1956.99Zm9.15,9.06-1.47.31a3.59,3.59,0,0,0,1.06-2.1l-1.77,1.1a3.938,3.938,0,0,0-3.59-2.36,3.993,3.993,0,0,0-3.94,4.03,4.081,4.081,0,0,0,.11.93c-2.2-.87-5.64-2.22-6.16-2.42a4.486,4.486,0,0,0,.25,3.07,4.552,4.552,0,0,0,.71,1.09c-0.66-.03-1.15-0.05-1.31-0.05a3.893,3.893,0,0,0,1.05,2.49,3.7,3.7,0,0,0,.79.64c-0.67.18-1.22,0.32-1.36,0.36a2.332,2.332,0,0,0,1.03,1.25,4.585,4.585,0,0,0,1.67.46,9.629,9.629,0,0,1-2.91.45,8.921,8.921,0,0,1-1.16-.07,9.406,9.406,0,0,0,5.51,1.77,9.765,9.765,0,0,0,9.64-9.17A4.521,4.521,0,0,0,1274,1966.05Z" transform="translate(-1252.09 -1957)"/>
          </svg>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Weka.IO">
          <svg xmlns="http://www.w3.org/2000/svg" width="25.54" height="26" viewBox="0 0 25.54 26">
            <path fill="#fff" fillRule="evenodd" d="M1306.23,1956.99a13.012,13.012,0,1,0,12.76,13.01A12.89,12.89,0,0,0,1306.23,1956.99Zm3.38,12.95h-2.21v8.04h-3.28v-8.04h-1.56v-2.83h1.56v-1.84a3.1,3.1,0,0,1,3.31-3.37l2.42,0.01v2.75h-1.76a0.674,0.674,0,0,0-.69.78v1.67h2.49Z" transform="translate(-1293.47 -1957)"/>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default Footer