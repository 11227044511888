import React, {memo} from "react"
import { TooltipHolder } from '/src/storybook/components/TooltipHolder'
import './ThresholdNumber.scss'

const ThresholdNumber = memo(({ type, number, threshold }) => {
  const content = (
    <>
      <div className="warning-part">
        <span className="exclamationMark">!</span>
        Less than requirement but worth considering
      </div>
      <div>Within {threshold} of required target</div>
    </>
  )

  return (
    <TooltipHolder
      content={content}
      holder={<span>{number}</span>}
      holderClassName='red'
      className={`${type}-tooltip threshold-tooltip`}
    />
  )
})

export default ThresholdNumber