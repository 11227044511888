import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'
import { Button } from '/src/storybook/components/Button'
import { Capacity } from '/src/routes/SizerCalculator/RequirementsPane/Capacity'
import { AdvancedPerformance } from '/src/routes/SizerCalculator/RequirementsPane/AdvancedPerformance'
import { Performance } from '/src/routes/SizerCalculator/RequirementsPane/Performance'
import { CustomerAndDeal } from '/src/routes/SizerCalculator/RequirementsPane/CustomerAndDeal'
import { OutputControl } from '/src/routes/SizerCalculator/RequirementsPane/OutputControl'
import { requirementsSelector } from './RequirementsPane.module'
import './RequirementsPane.scss'


const RequirementsPaneView = memo(({
    handlerWithResultsScroll,
    disabled,
    exposeCustomRA,
    hiddenRequirements,
    showExactOption,
    partnerVendors,
    downloadAll,
    user
  }) => {
  const { activeTab, cold_capacity, vendors, duty_cycle } = useSelector(requirementsSelector)
  const [ hotAndWarm, setHotAndWarm ] = useState(cold_capacity !== 0)
  const [ advancedPerformanceOpen, setAdvancedPerformanceOpen ] = useState(false)

  if (!vendors) {
    return null
  }

  return (
    <div className={classNames("requirements-pane", {disabled})}>
      <div className="header">
        <div>Start with</div>
        <b>YOUR REQUIREMENTS</b>
      </div>
      <Capacity
        exposeCustomRA={exposeCustomRA}
        hotAndWarm={hotAndWarm}
        setHotAndWarm={setHotAndWarm}
        hiddenRequirements={hiddenRequirements || []}
        handlerWithResultsScroll={handlerWithResultsScroll}
      />
      <div className="requirement-box">
        <Performance handlerWithResultsScroll={handlerWithResultsScroll}/>
        <AdvancedPerformance
          key={duty_cycle + activeTab}
          dutyCycle={duty_cycle}
          advancedPerformanceOpen={advancedPerformanceOpen}
          setAdvancedPerformanceOpen={setAdvancedPerformanceOpen}
          handlerWithResultsScroll={handlerWithResultsScroll}
        />
      </div>
      <CustomerAndDeal hiddenRequirements={hiddenRequirements || []} handlerWithResultsScroll={handlerWithResultsScroll}/>
      {!showExactOption &&
        <OutputControl partnerVendors={partnerVendors} handlerWithResultsScroll={handlerWithResultsScroll}/>
      }
      { _.get(user, 'email') === 'omri@weka.io' &&
        <div className="requirement-box">
          <Button label='Download All' onClick={downloadAll}/>
        </div>
      }
    </div>
  )
})

export default RequirementsPaneView
