import React, { memo } from 'react'
import { useDispatch } from "react-redux"

import { Toggle } from '/src/storybook/components/Toggle'
import { setRequirement } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const WarmTBUsage = memo(({ handlerWithResultsScroll, warmCostAndSpace }) => {
  const dispatch = useDispatch()

  return (
    <>
      <div className="requirement-row">
        Warm TB Usage
      </div>
      <div className="requirement-row">
        <b className="for-radio">Cost and space</b>
        <Toggle id={"cost-and-space"} boolean checked={warmCostAndSpace} onClick={
          handlerWithResultsScroll(newWarmCostAndSpace => {
            dispatch(setRequirement({warmCostAndSpace: newWarmCostAndSpace}))
          })
        }/>
      </div>
    </>
  )
})

export default WarmTBUsage