import React, { memo } from 'react'
import { useSelector } from "react-redux"
import _ from 'lodash'
import { customizedFieldsSelector, defaultFieldsSelector } from '/src/routes/SizerCalculator/ResultsPane'
import AdvancedView from "./Advanced.view"


const Advanced = memo(({ network, warmCostAndSpace, advancedOpen,
                         setAdvancedOpen, showWarmOptions, activeTab, handlerWithResultsScroll }) => {
  const allDefaultFieldsSelector = useSelector(defaultFieldsSelector)
  const allCustomizedFields = useSelector(customizedFieldsSelector)

  const ADVANCED_FIELDS = ['stripe', 'parity', 'hotspare', 'memory']
  const original = _.pick(allDefaultFieldsSelector, ADVANCED_FIELDS)
  const bySizingTab = activeTab === "BY SIZING"

  let changed = network !== "400"
  if (showWarmOptions) {
    changed = changed || !warmCostAndSpace
  }
  if (bySizingTab) {
    changed = changed || !_.isEqual(original, _.pick(allCustomizedFields, ADVANCED_FIELDS))
  }

  const props = {
    bySizingTab,
    allCustomizedFields,
    advancedOpen,
    handlerWithResultsScroll,
    changed,
    network,
    original,
    showWarmOptions,
    setAdvancedOpen,
    warmCostAndSpace
  }

  return (
    <AdvancedView {...props}/>
  )
})

export default Advanced
