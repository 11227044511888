import React, { memo } from 'react'
import {useDispatch, useSelector} from "react-redux"
import { Input } from '/src/storybook/components/Input'
import {
  requirementsSelector,
  setRequirement
} from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const BundleFields = memo(({ handlerWithResultsScroll }) => {
  const dispatch = useDispatch()
  const { numberOfBundles} = useSelector(requirementsSelector)

  return (
    <div className="requirement-row number-of-enclosures">
      <b># of Bundles</b>
      <Input
        className="bundles"
        key={numberOfBundles}
        size="medium"
        value={numberOfBundles}
        isNumber
        onChange={handlerWithResultsScroll(newNumberOfBundles =>
          dispatch(setRequirement({numberOfBundles: parseInt(newNumberOfBundles)}))
        )}
      />
    </div>
  )
})

export default BundleFields