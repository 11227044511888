import React, { memo, useState } from 'react'

import { labelModifiersSelector } from '/src/routes/Login'

import { STRINGS } from '/src/app/consts'
import { Input } from '/src/storybook/components/Input'
import { HotAndWarmToggle } from '../HotAndWarmToggle'
import { Advanced } from '../Advanced'
import { useDispatch, useSelector } from 'react-redux'
import {
  setRequirement,
  requirementsSelector
} from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const ByRequirementsTab = memo(({hotAndWarm, setHotAndWarm, handlerWithResultsScroll}) => {
  const [ advancedOpen, setAdvancedOpen ] = useState(false)
  const labelModifiers = useSelector(labelModifiersSelector)
  const strings = { ...STRINGS, ...labelModifiers }
  const { CAPACITY_LABEL, CAPACITY_SECONDARY_LABEL } = strings
  const { activeTab, capacity, network, warmCostAndSpace } = useSelector(requirementsSelector)
  const dispatch = useDispatch()
  
  return (
    <div className="first-tab requirement-box">
      <div className="requirement-row">
        <div>
          <b>{ CAPACITY_LABEL }</b>
          <div><b>{ CAPACITY_SECONDARY_LABEL }</b></div>
        </div>
        <Input
          size="small"
          value={capacity}
          isNumber
          onChange={handlerWithResultsScroll(newCapacity =>
            dispatch(setRequirement({capacity: newCapacity}))
          )}/>
        <b>TB</b>
      </div>
      <HotAndWarmToggle
        hotAndWarm={hotAndWarm}
        setHotAndWarm={setHotAndWarm}
        handlerWithResultsScroll={handlerWithResultsScroll}
      />
      <Advanced
        key={network + hotAndWarm + activeTab}
        network={network}
        activeTab={activeTab}
        warmCostAndSpace={warmCostAndSpace}
        advancedOpen={advancedOpen}
        setAdvancedOpen={setAdvancedOpen}
        showWarmOptions={hotAndWarm}
        handlerWithResultsScroll={handlerWithResultsScroll}
      />
    </div>
  )
})

export default ByRequirementsTab