import React, { memo } from 'react'
import { useSelector } from "react-redux"
import _ from 'lodash'
import { customizedFieldsSelector, defaultFieldsSelector } from '/src/routes/SizerCalculator/ResultsPane'
import AdvancedPerformanceView from "./AdvancedPerformance.view"


const AdvancedPerformance = memo(({ advancedPerformanceOpen, dutyCycle, setAdvancedPerformanceOpen,
                                    handlerWithResultsScroll }) => {
  const changed = dutyCycle != 100

  const props = {
    advancedPerformanceOpen,
    handlerWithResultsScroll,
    changed,
    dutyCycle,
    setAdvancedPerformanceOpen
  }

  return (
    <AdvancedPerformanceView {...props}/>
  )
})

export default AdvancedPerformance
