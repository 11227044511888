import React, { memo } from 'react'
import { useDispatch } from "react-redux"

import { Input } from '/src/storybook/components/Input'
import { TooltipHolder } from '/src/storybook/components/TooltipHolder'
import { setRequirement } from '../../RequirementsPane.module'


const DutyCycle = memo(({dutyCycle, handlerWithResultsScroll}) => {
  const dispatch = useDispatch()
  const dutyCycleHelp = (
    <div>
      To be used for endurance calculations
    </div>
  )

  return (
    <div className="requirement-row">
      <b className="for-radio">Duty Cycle</b>
      <Input
        key={dutyCycle}
        className="duty-cycle"
        size="small"
        value={dutyCycle}
        isNumber
        max={100}
        onChange={handlerWithResultsScroll(newDutyCycle =>
          dispatch(setRequirement({duty_cycle: newDutyCycle}))
        )}
      />
      <b>%</b>
      <TooltipHolder content={dutyCycleHelp}/>
    </div>
  )
})

export default DutyCycle