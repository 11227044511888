import React, { memo } from 'react'
import _ from 'lodash'
import { priceTooltipColumns } from '../../ResultsPaneConsts'


const Help = memo(({hiddenOutput, option, valuesBlock1, valuesBlock2, valuesBlock3}) => {
  const withCold = _.get(option, "cold_capacity") > 0 && _.get(option, "warm_cost_and_space")

  return (
    <>
      <div>
        {_.map(valuesBlock1, (key, displayName) => {
          if (withCold && displayName === 'Breakdown Titles') {
            return (
              <div key={_.kebabCase(displayName)}>
                <div className="priceCell"></div>
                {_.map(priceTooltipColumns, column => (<div className="priceCell"><b>{column}</b></div>))}
              </div>
            )
          }

          const value = _.get(option, key)
          if (typeof value === 'object') {
            return (
              <div key={_.kebabCase(displayName)}>
                <div className="priceCell"><b>{displayName}</b></div>
                {_.map(value, val => (
                  <div className="priceCell" key={val}>{val.toLocaleString()}</div>
                ))}
              </div>
            )
          }
          if (displayName !== 'Breakdown Titles' && value !== 'N/A' && !_.includes(hiddenOutput, displayName)) {
            return (
              <div key={_.kebabCase(displayName)}>
                <div><b>{displayName}</b></div>
                <div>{value && value.toLocaleString()}</div>
              </div>
            )
          }
        })}
      </div>
      { _.map([valuesBlock2, valuesBlock3], (valuesBlock, index) => {
        if (valuesBlock) {
          return (
            <div key={`block-${index+2}`}>
              {_.map(valuesBlock, (key, displayName) => {
                let value = _.get(option, key)
                if (withCold && typeof value !== 'object') {
                  value = ['', value, '']
                }
                if (typeof value === 'object') {
                  return (
                    <div key={_.kebabCase(displayName)}>
                      <div className="priceCell"><b>{displayName}</b></div>
                      {_.map(value, val => {
                        if (val && (key === 'cost/tb' || key === 'cost/bw')) {
                          val = Math.round(val).toLocaleString()
                        }
                        return (
                          <div className="priceCell" key={key + val}>{val && index === 0 ? `$${val}` : val}</div>
                        )
                      })}
                    </div>
                  )
                }
                if (key === 'cost/tb' || key === 'cost/bw') {
                  value = Math.round(value).toLocaleString()
                }
                return (
                  <div key={_.kebabCase(displayName)}>
                    <div><b>{displayName}</b></div>
                    <div>{value && index === 0 ? `$${value}` : value}</div>
                  </div>
                )
              })}
            </div>
          )
        }
      })}
    </>
  )
})

export default Help
