import React, { memo } from 'react'
import { Tabs } from '/src/storybook/components/Tabs'
import { ByRequirementsTab } from './ByRequirementsTab'
import { BySizingTab } from './BySizingTab'
import { useDispatch, useSelector } from 'react-redux'
import { setRequirement, requirementsSelector } from '../RequirementsPane.module'
import './Capacity.scss'


const Capacity = memo(({ exposeCustomRA, hotAndWarm, setHotAndWarm, hiddenRequirements, handlerWithResultsScroll}) => {
  const { activeTab } = useSelector(requirementsSelector)
  const dispatch = useDispatch()

  return (
    <div className="system-capacity">
      <div className="requirement-box-title">System capacity</div>
      <div>
        <Tabs tabs = {[
            {name: 'BY REQUIREMENT', content: <ByRequirementsTab
              hotAndWarm={hotAndWarm}
              setHotAndWarm={setHotAndWarm}
              handlerWithResultsScroll={handlerWithResultsScroll}
            />},
            {name: 'BY SIZING', content: <BySizingTab
              exposeCustomRA={exposeCustomRA}
              hotAndWarm={hotAndWarm}
              setHotAndWarm={setHotAndWarm}
              hiddenRequirements={hiddenRequirements}
              handlerWithResultsScroll={handlerWithResultsScroll}
            />}
          ]}
          onSelected={handlerWithResultsScroll((tabName) => dispatch(setRequirement({activeTab: tabName})))}
          selected={activeTab}
        />
      </div>
    </div>
  )
})

export default Capacity
