import React, { memo } from 'react'

export default memo(() => (
  <svg width="16px" height="16px" viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="IOPS/hover/admin-mode/expand-left" transform="translate(-1611.000000, -655.000000)">
            <g id="Group-27" transform="translate(1366.000000, 550.000000)">
                <g id="Group-6" transform="translate(245.000000, 105.000000)">
                    <path className="background" d="M8,0 C12.4266667,0 16,3.57333333 16,8 C16,12.4266667 12.4266667,16 8,16 C3.57333333,16 0,12.4266667 0,8 C0,3.57333333 3.57333333,0 8,0 Z" id="Path" fill="#000000"></path>
                    <path d="M9.13626185,4 C9.71208728,4 10,4.408145 10,4.87581114 C10,5.45983441 9.49980537,6 8.84877886,6 C8.30346358,6 7.98547043,5.66435444 8.00051065,5.10942045 C8.00051065,4.64264936 8.37909438,4 9.13626185,4 Z M7.26478261,13 C6.80478261,13 6.46782609,12.6987757 6.78956522,11.3719104 L7.3173913,9.01940402 C7.40913043,8.64333564 7.42434783,8.49226149 7.3173913,8.49226149 C7.17956522,8.49226149 6.58304348,8.75190575 6.22956522,9.00831601 L6,8.6017556 C7.11826087,7.59182259 8.40478261,7 8.95695652,7 C9.41652174,7 9.49304348,7.58812659 9.26347826,8.49226149 L8.65869565,10.964888 C8.55173913,11.4014784 8.5973913,11.5520906 8.70478261,11.5520906 C8.8426087,11.5520906 9.29478261,11.3709864 9.73913043,10.994456 L10,11.3705244 C8.91217391,12.5472395 7.72391304,13 7.26478261,13 Z" fill="#FFFFFF" fillRule="nonzero"></path>
                </g>
            </g>
        </g>
    </g>
  </svg>
))
