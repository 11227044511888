import _ from 'lodash'
import { makeReducer } from '/src/utils/reduxUtils'
import { SIZER_CALCULATOR_KEY } from '/src/routes/SizerCalculator'

export const REQUIREMENTS_KEY = 'REQUIREMENTS'

// ACTIONS
const SET_REQUIREMENT = `${REQUIREMENTS_KEY}.SET_REQUIREMENT`
export const setRequirement = requirement => ({
  type: SET_REQUIREMENT,
  payload: requirement
})

const SET_CUSTOM_RA_FIELD = `${REQUIREMENTS_KEY}.SET_CUSTOM_RA_FIELD`
export const setCustomRaFields = requirement => ({
  type: SET_CUSTOM_RA_FIELD,
  payload: requirement
})

const CLEAR_REQUIREMENTS = `${REQUIREMENTS_KEY}.CLEAR_REQUIREMENTS`
export const clearRequirements = requirement => ({
  type: CLEAR_REQUIREMENTS,
  payload: requirement
})

const SET_REQUIREMENTS_DATA = `${REQUIREMENTS_KEY}.SET_REQUIREMENTS_DATA`
export const setRequirementsData = requirementsData => ({
  type: SET_REQUIREMENTS_DATA,
  payload: requirementsData
})

const SET_SELECTED_VENDORS = `${REQUIREMENTS_KEY}.SET_SELECTED_VENDORS`
export const setSelectedVendors = selectedVendors => ({
  type: SET_SELECTED_VENDORS,
  payload: selectedVendors
})

// SELECTORS
// TODO: Check how can we get here only the relevant state
export const requirementsSelector = state => _.get(state, [SIZER_CALCULATOR_KEY, REQUIREMENTS_KEY])

const INITIAL_STATE = {
  vendors: null, // Shouldn't be sent in calculate request
  selectedVendors: null, // Shouldn't be sent in calculate request
  vendorOptions: null, // Shouldn't be sent in calculate request
  activeTab: "BY REQUIREMENT",

  capacity: 600,
  cold_capacity: 0,
  vendor: null,
  ra: null,
  numberOfEnclosures: null,
  numberOfBundles: 1,
  SSDsPerEnclosure: null,

  performance_type: "iops",
  read_part: 100,
  iops: 1,
  bw: 1,
  ioSize: 4,
  ioSizeUnit: "KB",

  license: '3Y',
  customer_type: 'Enterprise',
  license_type: 'DPO',
  feature_set: 'GA',
  deployment_type: 'production',

  sorting: 'by_cost',
  
  network: '400',
  duty_cycle: 100,
  warmCostAndSpace: true,
  customRaFields: null
}

export default makeReducer({
  [SET_REQUIREMENT]: (state, { payload }) => {
    const vendor = _.get(payload, 'vendor')
    const ra = _.get(payload, 'ra')
    let newState = {}
    if (vendor && !ra) {
      newState.vendorOptions = _.find(_.get(state, 'vendors'), v => v[0] === vendor)[1]
      newState.ra = _.get(newState, ['vendorOptions', 0, 'ra'])
      newState.numberOfEnclosures = _.get(newState, ['vendorOptions', 0, 'number_of_enclosures'])
      newState.numberOfBundles = 1
      newState.SSDsPerEnclosure = _.last(_.get(newState, ['vendorOptions', 0, 'ssds']))
    } else if (ra) {
      newState.vendorOptions = _.find(_.get(state, 'vendors'), v => v[0] === vendor)[1]
      const raOptions = _.find(_.get(newState, 'vendorOptions'), v => _.get(v, 'ra') === ra)
      newState.numberOfEnclosures = _.get(raOptions, 'number_of_enclosures')
      newState.numberOfBundles = 1
      newState.SSDsPerEnclosure = _.last(_.get(raOptions, 'ssds'))
    } else {
      return {...state, ...payload}
    }
    return {...state, ...payload, ...newState}
  },
  [SET_CUSTOM_RA_FIELD]: (state, { payload }) => {
    const customRaFields = _.get(state, 'customRaFields')
    return {...state, customRaFields: {...customRaFields, ...payload}}
  },
  [SET_REQUIREMENTS_DATA]: (state, { payload }) => {

    const vendors = _.get(payload, "vendors")
    const performanceProfiles = _.get(payload, "performanceProfiles")
    const { vendorOptions } = state
    // TODO: refactor this
    if (vendorOptions) {
      return {...state, vendors, performanceProfiles}
    } else {
      const vendorOptions = _.first(vendors)[1]
      const newState = {
        vendor: _.first(vendors)[0],
        vendorOptions,
        ra: _.get(vendorOptions, [0, 'ra']),
        numberOfEnclosures: _.get(vendorOptions, [0, 'number_of_enclosures']),
        SSDsPerEnclosure: _.last(_.get(vendorOptions, [0, 'ssds'])),

        vendors,
        selectedVendors: _.map(vendors, v => _.get(v, 0)),
        performanceProfiles
      }
      return {...state, ...newState}
    }
  },
  [SET_SELECTED_VENDORS]: (state, { payload }) => {
    return {...state, selectedVendors: payload}
  },
  [CLEAR_REQUIREMENTS]: () => INITIAL_STATE

}, INITIAL_STATE)