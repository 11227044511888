import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import './Checkbox.scss'

/**
 * Primary UI component for user interaction
 */
const Checkbox = ({ name, checked, items, onChecked, ...props }) => {
  const [isChecked, setIsChecked] = useState(checked)
  
  const onChange = () => {
    setIsChecked(!isChecked)
    if (_.isFunction(onChecked)) {
      onChecked(!isChecked)
    }
  }

  return (
    <label className="container">
      { name }
      <input type="checkbox" checked={isChecked} onChange={onChange} {...props}/>
      <span className="checkmark"></span>
    </label>
  )
}

Checkbox.propTypes = {
  /**
   * Checkbox name
   */
  name: PropTypes.string,
  /**
   * Checked
   */
  checked: PropTypes.bool,
  /**
   * Optional checked handler
   */
  onChecked: PropTypes.func,
};

Checkbox.defaultProps = {
  onChecked: undefined,
}

export default Checkbox