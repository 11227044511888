import React, { memo, useState } from 'react'

import classNames from 'classnames'
import ArrowDown from '/src/storybook/icons/ArrowDown'
import Reset from '/src/storybook/icons/Reset'
import { useDispatch } from 'react-redux'
import { setCustomRaFields, setRequirement } from '../RequirementsPane.module'
import DutyCycle from './Fields/DutyCycle'
import './AdvancedPerformance.scss'


const AdvancedPerformanceView = memo(({
    advancedPerformanceOpen,
    handlerWithResultsScroll,
    changed,
    dutyCycle,
    setAdvancedPerformanceOpen
  }) => {
  const dispatch = useDispatch()

  return (
    <div className={classNames("advanced", {open: advancedPerformanceOpen})} key={`advanced-performance-${changed}`}>
      <div className="advanced-title" onClick={() => setAdvancedPerformanceOpen(!advancedPerformanceOpen)}>
        <div>
          Advanced
          { changed && (
            <span> (Changes were made)</span>
          )}
        </div>
        <ArrowDown/>
      </div>
      <DutyCycle
        handlerWithResultsScroll={handlerWithResultsScroll}
        dutyCycle={dutyCycle}
      />
      <div className={classNames("requirement-row reset", {changed})}>
        <b></b>
        <div onClick={handlerWithResultsScroll(() => {
          dispatch(setRequirement({duty_cycle: 100}))
        })}>
          <Reset/>
          Reset
        </div>
      </div>
    </div>
  )
})

export default AdvancedPerformanceView
