import React, { memo } from 'react'

export default memo((props) => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-1673.000000, -959.000000)">
                <g transform="translate(1659.000000, 947.000000)">
                    <g transform="translate(14.000000, 12.000000)">
                        <path d="M24,12 C24,18.627 18.627,24 12,24 C5.373,24 0,18.627 0,12 C0,5.373 5.373,0 12,0 C18.627,0 24,5.373 24,12" fill="#FFFFFF"></path>
                        <polygon fill="#36C7A0" points="10.4829931 17.3413 5.18635025 11.9433914 6.75817193 10.401289 10.4763888 14.1921531 17.1456238 7.3413 18.7240498 8.87679807"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </svg>
))
