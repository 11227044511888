import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { Button } from '../../components/Button'
import { Input } from '../../components/Input'
import { Loader } from '../../components/Loader'
import { CUSTOM_MODAL_STYLES } from '../consts'
import '../../styles/modal.scss'


const ExportFileNameModal = ({ isOpen, setIsOpen, exportInProgress, setExportInProgress, exportHandler }) => {

  const [ name, setName ] = useState("")
  useEffect(() => {
    if(exportInProgress) {
      // exportHandler doing the actual download which is blocking. That's why delay is needed
      _.delay(() => {
        exportHandler(name)
        setName("")
      }, 100)
    }
  })

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={CUSTOM_MODAL_STYLES}
      id="export-file-name-modal"
      ariaHideApp={false}
    >
      <div className="modal-header">Export file name</div>
      <div className="modal-content">
        <div className="row">
          <div>Name</div>
          <Input value={name} onChange={(value) => setName(value)}/>
        </div>
        <div className="modal-footer">
          <div className="buttons">
            <Button secondary onClick={() => {
              setName("")
              setIsOpen(false)
            }} label='Cancel'/>
            <Button
              className='save'
              onClick={() => {
                setExportInProgress(true)
              }} 
              disabled={name === "" || exportInProgress}
              disabledTooltip='Please enter the name' 
              label='Save'
            />
          </div>
        </div>
        { exportInProgress && <Loader isHV={_.startsWith(window.location.hostname, 'hv.')}/> }
      </div>
    </Modal>
  )
}

ExportFileNameModal.propTypes = {
  /**
   * If Modal is open or not
   */
  isOpen: PropTypes.bool,
  /**
   * Open and Close the modal function
   */
  setIsOpen: PropTypes.func,
  /**
   * Export handler function
   */
  exportHandler: PropTypes.func
};

export default ExportFileNameModal
