import _ from 'lodash'
import React, { memo  } from 'react'
import './Page404.scss'


const Page404 = memo(() => (
  <div className="page-404">
    <h1>
      404
    </h1>
    <div>
      This URL is not mapped to any valid Weka Sizer instance. Please check the URL and contact the Weka team to get the right one.
    </div>
  </div>
))

export default Page404
