import React, { memo, useEffect, useRef } from 'react'
import _ from 'lodash'
import { downloadPDF } from '../ResultsPanePDFDownloadServices'
// import { EXPORT_MOCK } from "./Export.mock"
import ExportView from "./Export.view"
import './Export.scss'


const Export = memo(({exportData, setExportNameModalIsOpen, setExportInProgress }) => {
  // Uncomment for testing PDF export
  // exportData = EXPORT_MOCK
  const {requirements, data, raDetailsHeader, raDetails, exportName, resetExport} = exportData
  const ref = useRef()
  useEffect(async () => {
    if (exportName) {
      setTimeout(async () => {
        await downloadPDF(exportName, ref.current?.clientHeight, resetExport)
        setExportInProgress(false)
        setExportNameModalIsOpen(false)
      }, 2000) // So the component will have enough time to render for big pdf
    }
  }, [exportName])

  const requirementsUnitsSize = _.size(_.get(requirements, [1, 'columnContent', 2, 2])) > 4 ?
    'largeWidth' : 'smallWidth'

  const props = {
    data,
    requirements,
    requirementsUnitsSize,
    raDetailsHeader,
    raDetails
  }

  return (
    <ExportView {...props} ref={ref}/>
  )
})

export default Export