import React from "react"
import _ from "lodash"
import {
  nameHelpValues,
  serversEnclosuresHelpValues,
  bwHelpValues,
  iopsHelpValues, priceHelpValuesBlock1, priceHelpValuesBlock2, priceHelpValuesBlock3,
  priceTooltipColumns,
  spaceHelpValuesBlock
} from "../ResultsPaneConsts";


export const getCopyTooltipsHeaders = (read_part, isPartner, isWarm) => {
  const flattenHeadersMatrix = (rows, columns= priceTooltipColumns) => {
    let result = []
    _.forEach(rows, (row) => {
      if (_.startsWith(row, 'Performance') || _.startsWith(row, 'Bandwidth')) {
        result.push(`${row} - ${_.get(columns, 1)}`)
      } else {
        result = _.concat(result, _.map(columns, column => `${row} - ${column}`))
      }
    })
    return result
  }
  const connectSections = (sections) => {
    let result = []
    _.forEach(sections, (section) => {
      result = _.concat(result, _.keys(_.pickBy(section, _.size)))  // Removing tooltip headers
    })
    return result
  }
  let headers = connectSections([nameHelpValues, serversEnclosuresHelpValues, iopsHelpValues(read_part),
    bwHelpValues(read_part)])
  const space = _.keys(_.pickBy(spaceHelpValuesBlock, _.size))
  headers = _.concat(headers, isWarm ? flattenHeadersMatrix(space) : space)
  if (!isPartner) {
    const price = connectSections([priceHelpValuesBlock1, priceHelpValuesBlock2, priceHelpValuesBlock3])
    headers = _.concat(headers, isWarm ? flattenHeadersMatrix(price) : price)
  }
  return headers
}


const CopyHeadersToClipboard = ({ headers, readPart, isPartner, isWarm }) => {

  const values = _.concat(['Tag'], headers, getCopyTooltipsHeaders(readPart, isPartner, isWarm))
  // Fine tuning:
  let row = _.join(values, '\t')
  row = row.replace(" / ",  "\t") // For Servers / Enclosures
  return `${row}\n`
}

export default CopyHeadersToClipboard