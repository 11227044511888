import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import _ from 'lodash'
import { Input } from '../../components/Input'
import { Dropdown } from '../../components/Dropdown'
import { CUSTOM_MODAL_STYLES } from '../consts'
import { useDisableBodyScroll } from '../../hooks/DisableBodyScroll'
import BOMModalFooter from "./BOMModalFooter"
import '../../styles/modal.scss'


const BOMModal = ({ BOMModalData, setBOMModalData, user, callAPI, API_ENDPOINTS, mixpanelID }) => {
  useDisableBodyScroll(!_.isNull(BOMModalData))
  const [ BOMFormData, setBOMFormData] = useState({bomName: ''}) // Fields that we fill
  const BOMComment = _.get(BOMModalData, ['bom_dialog', 'BOM comment'])
  
  const reset = () => {
    setBOMFormData({bomName: ''})
    setBOMModalData(null)
  }
  return (
    <Modal
      isOpen={!_.isNull(BOMModalData)}
      onRequestClose={() => reset()}
      style={CUSTOM_MODAL_STYLES}
      ariaHideApp={false}
      id="bom-modal"
    >
      <div className="modal-header">Create BOM</div>
      <div className="modal-content">
        {BOMComment && ( <div className="comment"><i>{BOMComment}</i></div> )}
        <div className="content">
          <div className="row">
            <div>BOM name</div>
            <Input onChange={(value) => setBOMFormData({...BOMFormData, bomName: value})}/>
          </div>
          {
            _.map(_.get(BOMModalData, ['bom_dialog', 'config dialog']), field => {
              let dropdownProps = {}
              const fieldName = _.get(field, 'name')
              // Building the options for the dropdown
              const dropdownOptions = _.map(_.get(field, 'values'), (optionValue, label) => {
                if (_.get(optionValue, "default")) {
                  dropdownProps['selected'] = label
                }
                return { value: label, label,
                  isDisabled: _.get(BOMModalData, "cold_capacity") > 0 && _.get(optionValue, "disableOn")
                }
              })
              // Set form initial values
              if (dropdownProps['selected'] && !_.includes(_.keys(BOMFormData), fieldName)) {
                setBOMFormData({...BOMFormData, [fieldName]: dropdownProps['selected']})
              }
              return (
                <div className="row" key={fieldName}>
                  <div>{_.get(field, 'label')}</div>
                  <Dropdown
                    options={dropdownOptions}
                    managedState={false}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    onChange={(option) => setBOMFormData({...BOMFormData, [fieldName]: _.get(option, 'value')})}
                    {...dropdownProps}
                  />
                </div>
              )
            })
          }
        </div>
        <BOMModalFooter {...{ BOMModalData, BOMFormData, user, callAPI, API_ENDPOINTS, mixpanelID, reset }} />
      </div>
    </Modal>
  )
}

BOMModal.propTypes = {
  /**
   * BOMModal Data
   */
  BOMModalData: PropTypes.object,
  /**
   * Function for setting BOMModal Data
   */
  setBOMModalData: PropTypes.func,
  /**
   * User which is currently logged in
   */
  user: PropTypes.object,
  /**
   * Function for calling BE API
   */
  callAPI: PropTypes.func,
   /**
   * Available endpoints
   */
  API_ENDPOINTS: PropTypes.object
}

export default BOMModal