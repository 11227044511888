import React, { memo } from 'react'
import { useDispatch } from "react-redux"
import _ from "lodash"

import { Dropdown } from '/src/storybook/components/Dropdown'
import { setCustomRaFields } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const HotSpare = memo(({ allCustomizedFields, handlerWithResultsScroll }) => {
  const dispatch = useDispatch()
  const hotspare = _.get(allCustomizedFields, 'hotspare')

  return (
    <div className="requirement-row">
      <b>Hotspare</b>
      <Dropdown
        key={hotspare}
        className="hotspare"
        options={_.map(_.range(0, 5), value => ({value, label: value}))}
        selected={hotspare}
        managedState={false}
        onChange={handlerWithResultsScroll(newHotSpare => {
          dispatch(setCustomRaFields({
            ...allCustomizedFields,
            hotspare: _.get(newHotSpare, 'value')
          }))
        })}
      />
    </div>
  )
})

export default HotSpare
