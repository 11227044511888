import _ from 'lodash'


export const createBOMXlsxData = (output, option, hostname, email, callAPI, API_ENDPOINTS) => {
  let skuData = []
  const {
    bom_dialog,
    capacity,
    cold_capacity, 
    customer_type,
    license_type,
    enclosures, 
    license, 
    populated_ssds,
    servers, 
    total_servers_raw, 
    weka_license_unit
  } = option
  
  _.forOwn(output, function(value, key) { 
    if (key === 'weka_license_sku') {
      let sku = value['SSD'][customer_type][license]
      sku.quantity = weka_license_unit === "net" ? capacity : Math.round(parseFloat(total_servers_raw))
      skuData.push([sku.priority || 0, parseInt(sku.quantity), sku.SKU, sku.description, sku.adhoc])
      if (cold_capacity > 0) {
        if (_.has(value['OBS'], license_type)) {
          sku = value['OBS'][license_type][customer_type][license]
        } else {
          sku = value['OBS'][customer_type][license]
        }
        sku.quantity = cold_capacity
        skuData.push([sku.priority || 0, parseInt(sku.quantity), sku.SKU, sku.description, sku.adhoc])
      }
    } else {
      _.forEach(value, (sku) => {
        let quantity

        if (cold_capacity > 0 && 'conditional-quantity' in sku) {
          quantity = eval(sku["conditional-quantity"]["WarmTierExists"])
        } else if ('conditional-quantity' in sku) {
          quantity = eval(sku["conditional-quantity"]["default"])
        } else {
          quantity = sku.quantity
        }
        
        let multiplier = 1
        if (key === 'enclosure_skus') {
          multiplier = enclosures
        }
        if (key === 'server_skus') {
          multiplier = servers
        }
        if (key === 'ssd_skus') {
          multiplier = servers * populated_ssds
        }

        // Selecting SKU string by license
        const skuValue = _.isString(sku.SKU) ? sku.SKU : sku.SKU[license]
        skuData.push([sku.priority || 0, quantity * multiplier, skuValue, sku.description])
      })
    }
  })
  skuData.sort((a,b) => a[0] - b[0])

  // Adding excel headers
  let xlsxData = []
  let dataForLog = {}
  let dataForLogCounter = 1
  const splittedPreamble = bom_dialog.formatting.preamble.split("\\n")
  xlsxData.push([splittedPreamble[0]])
  xlsxData.push(splittedPreamble[1].split(","))
  
  // Adding excel data
  _.forEach(skuData, (row) => {
    row.shift() // remove priority value
    xlsxData.push(row)
    dataForLog["row " + dataForLogCounter] = row.join(",")
    dataForLogCounter += 1
  })

  // Send log event
  if (hostname !== "localhost") {
    callAPI({
      endpoint: API_ENDPOINTS.POST_LOGGER,
      payload: {
        event_type: 'BOM Creation',
        hostname,
        timezone_offset: new Date().getTimezoneOffset(),
        email,
        data: dataForLog
      }
    })
  }
  return xlsxData
}

export const sendBOMSlack = (fileBlob, bomName, name, hostname, callAPI, API_ENDPOINTS) => {
  const reader = new FileReader()
  reader.readAsDataURL(fileBlob)
  reader.onloadend = () => {
    callAPI({ endpoint: API_ENDPOINTS.POST_SEND_BOM_ON_SLACK, payload: {
      message: "BOM file was created by " + name + ":", file: reader.result, filename: bomName, hostname
    }})
  }
}