import React, { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from "@auth0/auth0-react"
import API_ENDPOINTS from '/src/app/apiEndpoints'
import { setUserData } from '/src/routes/Login'
import { callAPI } from '/src/utils/apiUtils'



const Logout = memo(() => {
  const dispatch = useDispatch()
  const { logout } = useAuth0()

  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const withServerLogout = urlParams.get('with_server_logout')
    const database = urlParams.get('database') || ''
    const type = urlParams.get('type') || 'ras'
    const actualLogout = async () => {
      dispatch(setUserData({}))
      const returnTo = window.location.origin
      await logout()
      window.location.href = returnTo
    }
    if (withServerLogout) {
      callAPI({
        endpoint: API_ENDPOINTS.GET_SERVER_LOGOUT,
        payload: {database, type},
        callback: actualLogout
      })
    }
    await actualLogout()
  }, [])

  return <div/>
})

export default Logout
