import _ from "lodash"
import React from "react"
import { formatNumber } from '/src/utils/formatting'
import Info from '/src/storybook/icons/Info'
import { TooltipHolder } from '/src/storybook/components/TooltipHolder'
import classNames from "classnames"

export const getSolutionHelpTooltips = (option) => {

  const spineBWSubTooltip = (
    <div>
      {_.map(_.get(option, "spine_bw_tooltip"), (value) => (
        <div key={_.kebabCase(`spine-bw-${value[0]}-sub-tooltip`)}>
          <div><b>{value[0]}</b></div>
          <div>{_.round(value[1], 1)}</div>
        </div>
      ))}
    </div>
  )

  const memorySubTooltip = (
    <div>
      {_.map(_.get(option, "memory_tooltip"), (value) => (
        <div key={_.kebabCase(`memory-${value[0]}-sub-tooltip`)}>
          <div><b>{value[0]}</b></div>
          <div>{_.round(value[1], 1)}</div>
        </div>
      ))}
    </div>
  )
  const filesAndDirsTooltip = ({bucketLimitData}) => (
    <div>
      {_.map(_.get(option, `files_and_directories_tooltip`), (row, index) => {
        if (index !== 0) {
          row[_.size(row) - 1] = bucketLimitData ? _.last(row) * _.min(_.values(bucketLimitData)) : 'N/A'
        }
        const [, ...rowValues] = row
        const rowMin = _.min(rowValues)
        return (
          <div key={_.kebabCase(`${_.get(row, 0)}-sub-tooltip`)}>
            {_.map(row, (cell, column) => (
              <>
                {cell === 'N/A' &&
                  <div className="filesAndDirsCell" key={row + cell}>N/A</div>
                }
                {cell !== 'N/A' &&
                  <div className="filesAndDirsCell" key={row + cell}>
                    {column === 0 || index === 0 ? cell :
                        (cell === rowMin ? <b>{formatNumber(cell)}</b> : formatNumber(cell))}
                  </div>
                }
              </>
            ))}
          </div>
        )
      })}
    </div>
  )
  return {spineBWSubTooltip, memorySubTooltip, filesAndDirsTooltip}
}

export const solutionHelpTooltipView = ({
  option,
  openPerformanceSubTooltip,
  setOpenPerformanceSubTooltip,
  tooltip,
  yOffset,
  displayName,
  value
}) => {
  const key = _.kebabCase(value)
  return (
    <div key={key} className={classNames(`${key}`, {'selected': openPerformanceSubTooltip === key})}>
      <div><b>{ displayName }</b></div>
      <div className="tooltipValue">
        <div>{_.get(option, value).toLocaleString()}</div>
        <TooltipHolder
          content={tooltip}
          holder={<Info/>}
          holderClassName="help-icon"
          placement="bottomLeft"
          align={{offset: [36, yOffset]}}
          className={classNames("sub-tooltip")}
          onClick={() => setOpenPerformanceSubTooltip(openPerformanceSubTooltip === key ? '' : key)}
        />
      </div>
    </div>
  )
}