import _ from 'lodash'
import { makeReducer } from '/src/utils/reduxUtils'
import { SIZER_CALCULATOR_KEY } from '/src/routes/SizerCalculator'

export const ADMIN_KEY = 'ADMIN'

// ACTIONS
const SET_WHITELIST_USERS = `${ADMIN_KEY}.SET_WHITELIST_USERS`
export const setWhitelistUsers = whitelistUsers => ({
  type: SET_WHITELIST_USERS,
  payload: whitelistUsers
})

// SELECTORS
// TODO: Check how can we get here only the relevant state
export const adminDataSelector = state => _.get(state, [ADMIN_KEY])

const INITIAL_STATE = {
  whitelistUsers: {}
}

export default makeReducer({
  [SET_WHITELIST_USERS]: (state, { payload }) => {
    return {...state, whitelistUsers: payload}
  },
}, INITIAL_STATE)