import React from "react"
import _ from "lodash"
import classNames from "classnames"
import { showSuccessNotification } from '/src/utils/notificationsUtils'
import { TooltipHolder } from '/src/storybook/components/TooltipHolder'
import {
  CopyHeadersToClipboard,
  getCopyTooltipsHeaders
} from '/src/routes/SizerCalculator/ResultsPane/CopyHeadersToClipboard'
import {
  nameHelpValues,
  serversEnclosuresHelpValues,
  priceHelpValuesBlock1,
  priceHelpValuesBlock2,
  priceHelpValuesBlock3,
  spaceHelpValuesBlock
} from "/src/routes/SizerCalculator/ResultsPane/ResultsPaneConsts"
import { valuesOfTooltip } from "./ValuesOfTooltip"
import { Help } from "../Help"
import { PerformanceHelp } from "../PerformanceHelp"


const CopyToClipboard = ({selectedForExport, isPartner, isWarm, headers, readPart}) => {

  const header = CopyHeadersToClipboard({headers, readPart, isPartner, isWarm})
  const sortedData = _.sortBy(_.toPairs(selectedForExport), 0)
  const options = _.map(sortedData, d => d[1])
  let rows = []

  _.forEach(options, ({ option, comment }, index) => {
    const weka_total_cost = _.get(option, 'weka_total_cost')
    const name = _.get(option, 'instance_name')
    const displayCost = _.get(option, 'display_cost')
    const priceValue = displayCost && weka_total_cost ? weka_total_cost.toLocaleString() : '-'
    const enclosures = _.get(option, 'enclosures')
    const servers = _.get(option, 'servers')
    const uSpace = _.get(option, 'u_space')
    const spaceValue = _.isArray(uSpace) ? _.get(uSpace, 0) : uSpace
    const withCold = _.get(option, "cold_capacity") > 0 && _.get(option, "warm_cost_and_space")

    let rowValuesForClipboard = [_.get(option, 'vendor'), name, servers, enclosures,
      _.get(option, 'populated_ssds'), _.get(option, 'capacity'), _.get(option, 'iops'),
      _.get(option, 'bw'), spaceValue]
    let tooltips = [
      (<TooltipHolder content={<Help option={option} valuesBlock1={nameHelpValues}/>}
                      className="result-help-tooltip"/>),
      (<TooltipHolder content={<Help option={option} valuesBlock1={serversEnclosuresHelpValues}/>}
                      className="result-help-tooltip"/>),
      (<TooltipHolder content={<PerformanceHelp option={option} read_part={readPart} isIOPS={true}/>}
                      className={classNames("result-help-tooltip", "performance")} align={{offset: [-82, 6]}}/>),
      (<TooltipHolder content={<PerformanceHelp option={option} read_part={readPart}/>}
                      className={classNames("result-help-tooltip", "performance")} align={{offset: [-82, 6]}}/>),
      (<TooltipHolder content={<Help option={option} valuesBlock1={spaceHelpValuesBlock} valuesBlock2={{}}/>}
                      className={classNames("result-help-tooltip", {"big": withCold})} {...(withCold ? {placement: 'left'} : {})}/>)
    ]
    if (weka_total_cost) {
      rowValuesForClipboard.push(priceValue)
      tooltips.push(<TooltipHolder
        content={<Help option={option} valuesBlock1={priceHelpValuesBlock1} valuesBlock2={priceHelpValuesBlock2}
                       valuesBlock3={priceHelpValuesBlock3}/>}
        className={classNames("result-help-tooltip", {"big": withCold})} {...(withCold ? {placement: 'left'} : {})}/>)
    }

    // First 2 tooltips has dynamic number of fields.
    // That's why in these tooltips we match the exact field name
    let values = _.concat([comment], _.clone(rowValuesForClipboard))
    const tooltips_headers = getCopyTooltipsHeaders(isPartner, isWarm)
    const firstTooltipSize = _.size(nameHelpValues) - 1
    _.forEach(tooltips, (tooltip, index) => {
      if (index === 0) { // dynamic tooltip - nameHelpValues
        const tooltipTitles = _.slice(tooltips_headers, 0, firstTooltipSize)
        const tooltipValues = _.fill(Array(firstTooltipSize), "")
        values = [...values, ...valuesOfTooltip(tooltip, tooltipTitles, tooltipValues)]
      } else if (index === 1) { // dynamic tooltip - serversEnclosuresHelpValues
        const fieldsNumber = _.size(serversEnclosuresHelpValues) - 1
        const tooltipTitles = _.slice(tooltips_headers, firstTooltipSize, firstTooltipSize + fieldsNumber)
        const tooltipValues = _.fill(Array(fieldsNumber), "")
        values = [...values, ...valuesOfTooltip(tooltip, tooltipTitles, tooltipValues)]
      } else {
        let tooltipValues = valuesOfTooltip(tooltip)
        if (_.last(rowValuesForClipboard) === '-' && index === tooltips.length - 1) { // Checking if price is empty
          tooltipValues = tooltipValues.map(v => "-")
        }
        values = [...values, ...tooltipValues]
      }
    })
    rows.push(_.join(values, '\t') + '\n')
  })

  // Copying
  navigator.clipboard.writeText(header + _.join(rows, '')).then(() => {
    showSuccessNotification({message: "Copied to clipboard"})
  }).catch(err => {
    console.error('Async: Could not copy text: ', err)
  })
}

export default CopyToClipboard