import { METHODS } from '/src/utils/apiUtils'
import config from '/src/config'

const apiEndpoints = {

  // Sizer Admin API
  POST_IS_ADMIN: { method: METHODS.POST, path: config.BACKEND_URL + 'is_admin' },

  // Sizer API
  GET_SERVER_LOGOUT: { method: METHODS.GET, path: config.BACKEND_URL + 'logout' },
  GET_REQUIREMENTS_DATA: { method: METHODS.GET, path: config.BACKEND_URL + 'requirements_data' },
  GET_ALLOWED_SITES: { method: METHODS.GET, path: config.BACKEND_URL + 'AllowedSites' },
  POST_CALC: { method: METHODS.POST, path: config.BACKEND_URL + 'TestWekaAWScalculator' },
  POST_RAS: { method: METHODS.POST, path: config.BACKEND_URL + 'ras' },
  POST_TOKEN: { method: METHODS.POST, path: config.BACKEND_URL + 'Token' },
  POST_LOGGER: { method: METHODS.POST, path: config.BACKEND_URL + 'Logger' },
  POST_SEND_EMAIL: { method: METHODS.POST, path: config.BACKEND_URL + 'SendEmail' },
  POST_SEND_BOM_ON_SLACK: { method: METHODS.POST, path: config.BACKEND_URL + 'send_slack_bom_file' },

  // Sizer Notion API
  GET_NOTION_RAS_VALIDATION: { method: METHODS.GET, path: config.BACKEND_URL + 'validate_notion_db' },
  POST_COPY_NOTION_DATA: { method: METHODS.POST, path: config.BACKEND_URL + 'copy_notion_data' },

  // Rest API
  GET_WHITELIST_USERS: { method: METHODS.GET, path: config.BACKEND_URL + 'api/whitelist_users' },
  ADD_WHITELIST_USER: { method: METHODS.POST, path: config.BACKEND_URL + 'api/whitelist_users' },
  UPDATE_WHITELIST_USER: { method: METHODS.PUT, path: config.BACKEND_URL + 'api/whitelist_users/' },
  DELETE_WHITELIST_USER: { method: METHODS.DELETE, path: config.BACKEND_URL + 'api/whitelist_users/' },

  // Vartopia API
  GET_ACCESS_TOKEN: { method: METHODS.POST, path: `https://${config.VARTOPIA_AUTH0_DOMAIN}/oauth/token` },
  GET_USER_INFO: { method: METHODS.GET, path: `https://${config.VARTOPIA_AUTH0_DOMAIN}/userinfo` },

  // Weka API
  GET_BUCKET_LIMIT: { method: METHODS.GET, path: config.WEKA_URL },
}

export default apiEndpoints
