import _ from 'lodash'
import { write, utils } from 'xlsx'
import { saveAs } from 'file-saver'
import { createBOMXlsxData, sendBOMSlack } from './BOMModalServices'


export const saveBOM = ({option, BOMFormData, hostname, user, callAPI, API_ENDPOINTS}) => {
  const data = _.get(option, 'bom_dialog')
  const { name, email } = user

  // Updating the output from the form
  let output = _.cloneDeep(_.get(data, 'output'))
  const { bomName } = BOMFormData
  _.forOwn(_.omit(BOMFormData, ['bomName']), function(value, key) {
    const opt = _.find(_.get(data, "config dialog"), (o) => o.name === key)
    _.forEach(opt.values[value]["skus"], (o) => {
      output[opt.element_unit].push(o)
    })
  })

  // Send log event
  const date = new Date()
  callAPI({
    endpoint: API_ENDPOINTS.POST_LOGGER,
    payload: {
      event_type: 'BOM Dialog',
      hostname,
      timezone_offset: date.getTimezoneOffset(),
      email,
      data: BOMFormData
    }
  }) 

  // Creating excel file
  let wb = utils.book_new()
  wb.Props = {
    Title: "BOM",
    Subject: "BOM",
    Author: "Weka",
    CreatedDate: date
  }

  // Creating excel sheet and adding output data
  wb.SheetNames.push("BOM")
  const ws_data = createBOMXlsxData(output, option, hostname, email, callAPI, API_ENDPOINTS)
  let ws = utils.aoa_to_sheet(ws_data)
  // Setting auto width for excel columns
  let objectMaxLength = []
  const [startLetter, endLetter] = ws['!ref'].replace(/\d/, '').split(':')
  const ranges = _.range(startLetter.charCodeAt(0), endLetter.charCodeAt(0) + 1)
  _.forEach(ranges, (c) => {
    const cell = String.fromCharCode(c)
    let maxLength = 0
    _.forEach(_.range(1,100), (i) => {
      if (ws['' + cell + i]) {
        const cellLength = ws['' + cell + i].v.length + 1
        maxLength = cellLength > maxLength ? cellLength : maxLength
      }
    })
    objectMaxLength.push({ width: maxLength })
  })
  ws['!cols'] = objectMaxLength

  // Download the excel
  wb.Sheets["BOM"] = ws;    
  const wbout = write(wb, {bookType:'xlsx',  type: 'binary'})
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    let view = new Uint8Array(buf)
    _.forEach(_.range(0, s.length), (i) => {
      view[i] = s.charCodeAt(i) & 0xFF
    })
    return buf
  }
  const fileBlob = new Blob([s2ab(wbout)])
  saveAs(fileBlob, bomName + '.xlsx')
  // sendBOMEmail(fileBlob, bomName + '.xlsx', _.get(option, 'notification_email'), name, callAPI, API_ENDPOINTS)
  sendBOMSlack(fileBlob, bomName + '.xlsx', name, hostname, callAPI, API_ENDPOINTS)
}
