import _ from "lodash"
import html2pdf from "html2pdf.js"


export const addFooter = (pdf) => {
  const totalPages = pdf.internal.getNumberOfPages()

  for (let i = 1; i <= totalPages; i++) {
    pdf.setPage(i)
    pdf.setFontSize(16)
    pdf.setTextColor(0)
    pdf.text('        Page ' + i + '/' + totalPages + '\n' + '@2022 WekaIO inc.',
      pdf.internal.pageSize.getWidth() / 2 - 70,
      pdf.internal.pageSize.getHeight() - 50)
  }
}

export const downloadPDF = async (name, contentHeight, resetExport) => {
  const pdfContent = document.getElementById("export")
  const extraHeight = 60 * (1 + _.toInteger(contentHeight / 1496)) //1586 - 90 = 1496 - One page
  const opts = {
    filename: `${name}.pdf`,
    margin: [0, 0, 90, 0],
    html2canvas: { y: 0, scrollY: 0, height: contentHeight + extraHeight },
    jsPDF: { orientation: 'landscape', hotfixes: ["px_scaling"], unit: "px", format: [1684, 1586] },
    pagebreak: { avoid: '.dont-page-break'}
  }

  await html2pdf().from(pdfContent).set(opts).toPdf().get('pdf').then(addFooter).save()

  resetExport()
}