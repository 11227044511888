import _ from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { callAPI } from '/src/utils/apiUtils'
import { showNotification } from '/src/utils/notificationsUtils'
import API_ENDPOINTS from '/src/app/apiEndpoints'
import PartnerLoginView from "./PartnerLogin.view"
import './PartnerLogin.scss'


const PartnerLogin = memo(({ emaildomain, partnername, hostname, email, error, isExpiredToken,
                             whitelistUsers }) => {
  const [ userEmail, setUserEmail ] = useState(email)
  const payload = { email: emaildomain ? `${userEmail}@${emaildomain}` : userEmail, hostname }
  // const payload = { email: 'alexander@weka.io', hostname }
  const tokenAPIPayload = { ...payload, partnername }

  const clickHandler = () => {
    if (whitelistUsers && !_.includes(_.keys(whitelistUsers), userEmail)) {
      showNotification({
        message: "Please review email or approach your Weka contact",
        type: "danger"
      });
    } else {
      callAPI({
        endpoint: API_ENDPOINTS.POST_TOKEN,
        payload: tokenAPIPayload,
        callback: () => {
          if (hostname !== "localhost") {
            callAPI({
              endpoint: API_ENDPOINTS.POST_LOGGER,
              payload: {...payload, event_type: "registration", timezone_offset: new Date().getTimezoneOffset() }
            })
            showNotification({
              message: "An email with access link was sent to your email address, please follow this link",
              type: "success",
            });
          }
        }
      })
    }
  }

  useEffect(() => {
    if (error) {
      showNotification({
        message: error,
        type: "danger",
      });
    }
  }, [error])

  const props = {
    emaildomain,
    partnername,
    userEmail,
    setUserEmail,
    isExpiredToken,
    clickHandler,
    tokenAPIPayload
  }
  return <PartnerLoginView {...props} />
})

export default PartnerLogin
