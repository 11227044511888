import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import './Toggle.scss'

/**
 * Primary UI component for user interaction
 */
const Toggle = ({ id, boolean, leftLabel, rightLabel, checked, onClick, ...props }) => {

  const handleChange = (e) => {
    onClick(e.target.checked)
  }

  return (
    <div className={classNames('toggle', {boolean})}>
      <div>{leftLabel}</div>
      <input id={id} type='checkbox' checked={checked} onChange={(e) => handleChange(e)} {...props} />
      <label htmlFor={id}></label>
      <div>{rightLabel}</div>
    </div>
  );
};

Toggle.propTypes = {
  /**
   * Toggle ID
   */
  id: PropTypes.string,
  /**
   * on/off Toggle or 2 options Toggle
   */
  boolean: PropTypes.bool,
  /**
   * Toggle checked
   */
  checked: PropTypes.bool,
  /**
   * Toggle left labels
   */
  leftLabel: PropTypes.string,
  /**
   * Toggle right labels
   */
  rightLabel: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Toggle.defaultProps = {
  onClick: undefined,
};

export default Toggle