import _ from "lodash"
import React, { memo } from 'react'
import {useDispatch} from "react-redux"
import { Input } from '/src/storybook/components/Input'
import { setCustomRaFields } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const SSDCores = memo(({handlerWithResultsScroll, allCustomizedFields}) => {
  const dispatch = useDispatch()
  const ssd_cores = _.get(allCustomizedFields, 'ssd_cores', '').toString()
  return (
    <div className="requirement-row">
      <b>SSD Cores</b>
      <Input
        key={ssd_cores}
        className="ssd-cores"
        size="medium"
        value={ssd_cores}
        isNumber
        onChange={handlerWithResultsScroll(newSSDCores => dispatch(setCustomRaFields({
          ...allCustomizedFields, ssd_cores: newSSDCores
        })))}
      />
    </div>
  )
})

export default SSDCores