import React, {memo, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"
import _ from 'lodash'
import classNames from 'classnames'
import ArrowDown from '/src/storybook/icons/ArrowDown'
import Reset from '/src/storybook/icons/Reset'
import { customizedFieldsSelector, defaultFieldsSelector } from '/src/routes/SizerCalculator/ResultsPane'
import { setCustomRaFields } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'
import BECores from "./Fields/BECores"
import CPUClock from "./Fields/CPUClock"
import FrontendCores from './Fields/FrontendCores'
import PortSpeed from "./Fields/PortSpeed"
import PortsNumber from "./Fields/PortsNumber"
import ProfileAndSSD from "./Fields/ProfileAndSSD"
import SSDCores from "./Fields/SSDCores"
import './CustomizeRa.scss'


const CustomizeRa = memo(({ hiddenRequirements, handlerWithResultsScroll }) => {
  const dispatch = useDispatch()
  const allDefaultFieldsSelector = useSelector(defaultFieldsSelector)
  const allCustomizedFields = useSelector(customizedFieldsSelector)
  const [ customizationOpen, setCustomizationOpen ] = useState(false)
  const CUSTOMIZED_RA_FIELDS = ['port_speed', 'network_ports', 'cpu_clock', 'be_cores', 'performance_profile',
    'ssd_model', 'ssd_cores', 'frontend_cores']
  const changed = !_.isEqual(_.pick(allDefaultFieldsSelector, CUSTOMIZED_RA_FIELDS),
    _.pick(allCustomizedFields, CUSTOMIZED_RA_FIELDS))
  const reset = () => dispatch(setCustomRaFields({...allCustomizedFields, ...allDefaultFieldsSelector}))

  return (
    <div className={classNames("requirement-row customised", {open: customizationOpen})}>
      <div className="customised-title" onClick={() => setCustomizationOpen(!customizationOpen)}>
        <b>
          Customize RA
          { changed && (
            <span> (Customized)</span>
          )}
        </b>
        <ArrowDown/>
      </div>
      <div className="customised-content">
        <PortsNumber handlerWithResultsScroll={handlerWithResultsScroll} allCustomizedFields={allCustomizedFields} />
        <PortSpeed handlerWithResultsScroll={handlerWithResultsScroll} allCustomizedFields={allCustomizedFields} />
        {!_.includes(hiddenRequirements, "CPUClock") && <CPUClock handlerWithResultsScroll={handlerWithResultsScroll} allCustomizedFields={allCustomizedFields} />}
        {!_.includes(hiddenRequirements, "performanceProfile") && <ProfileAndSSD handlerWithResultsScroll={handlerWithResultsScroll} allCustomizedFields={allCustomizedFields} />}
        <BECores handlerWithResultsScroll={handlerWithResultsScroll} allCustomizedFields={allCustomizedFields} />
        {!_.includes(hiddenRequirements, "SSDCores") && <SSDCores handlerWithResultsScroll={handlerWithResultsScroll} allCustomizedFields={allCustomizedFields} />}
        {!_.includes(hiddenRequirements, "FECores") && <FrontendCores handlerWithResultsScroll={handlerWithResultsScroll} allCustomizedFields={allCustomizedFields} />}
        <div className={classNames("requirement-row reset", {changed})}>
          <b></b>
          <div onClick={reset}>
            <Reset/>
            Reset
          </div>
        </div>
      </div>
    </div>
  )
})

export default CustomizeRa
