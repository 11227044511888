import React, { memo, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import classNames from 'classnames'
import API_ENDPOINTS from '/src/app/apiEndpoints'
import { callAPI } from '/src/utils/apiUtils'
import { Button } from '/src/storybook/components/Button'
import { Loader } from '/src/storybook/components/Loader'
import './NotionDatabaseValidation.scss'


const NotionDatabaseValidation = memo(() => {

  const history = useHistory()
  const [ status, setStatus ] = useState({})
  const errors = _.get(status, 'errors')
  const urlParams = new URLSearchParams(window.location.search)
  const database = urlParams.get('database') || ''
  const type = urlParams.get('type') || 'ras'

  useEffect(() => {
    if (_.isEmpty(status)) {
      callAPI({
        endpoint: API_ENDPOINTS.GET_NOTION_RAS_VALIDATION,
        payload: {database, type},
        callback: (data) => {
          setStatus(data)
        }
      })
    }
  }, [status])

  if (_.isEmpty(status)) {
    return (
      <div className="notion-database-validation">
        <Loader/>
      </div>
    )
  }

  return (
    <div className="notion-database-validation">
      <div className={classNames("response", {'has-errors': !_.isEmpty(errors)})}>{_.get(status, 'response')}</div>
      <ul className="errors">
        {_.map(errors, error => (
          <li>{error}</li>
        ))}
      </ul>
      {_.isEmpty(errors) &&
        <Button label={`Deploy to ${_.upperFirst(process.env.ENVIRONMENT)}`} onClick={() => history.push({
          pathname: "/logout",
          search: `?with_server_logout=True&type=${type}&database=${database}`
        })}/>
      }
    </div>
  )
})

export default NotionDatabaseValidation
