import _ from "lodash"
import React, { memo } from 'react'
import {useDispatch} from "react-redux"
import { Input } from '/src/storybook/components/Input'
import { setCustomRaFields } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const BECores = memo(({handlerWithResultsScroll, allCustomizedFields}) => {
  const dispatch = useDispatch()
  const be_cores = _.get(allCustomizedFields, 'be_cores', '').toString()
  return (
    <div className="requirement-row">
      <b>BE Cores</b>
      <Input
        key={be_cores}
        className="be-cores"
        size="medium"
        value={be_cores}
        isNumber
        onChange={handlerWithResultsScroll(newBECores => dispatch(setCustomRaFields({
          ...allCustomizedFields, be_cores: newBECores
        })))}
      />
    </div>
  )
})

export default BECores