import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './Button.scss'

/**
 * Primary UI component for user interaction
 */
const Button = ({ label, className, secondary, black, okta, disabled, disabledTooltip, ...props }) => {
  return (
    <button
      type="button"
      className={classNames('button', className, {secondary, black, okta})}
      title={disabled ? disabledTooltip : ''}
      disabled={disabled}
      {...props}
    >
      <div>{label}</div>
    </button>
  );
};

Button.propTypes = {
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Disabled button indicator
   */
  disabled: PropTypes.bool,
  /**
   * Tooltip to appear when hovering over disabled button
   */
  disabledTooltip: PropTypes.string,
  /**
   * Black button
   */
  black: PropTypes.bool,
  /**
   * With google icon or not
   */
  okta: PropTypes.bool,
  /**
   * Secondary button
   */
  secondary: PropTypes.bool,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  onClick: undefined,
  secondary: false,
  black: false,
  disabled: false,
  okta: false
};

export default Button