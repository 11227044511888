import _ from "lodash"
import React, { memo } from 'react'
import {useDispatch, useSelector} from "react-redux"
import { Dropdown } from '/src/storybook/components/Dropdown'
import {
  requirementsSelector,
  setCustomRaFields
} from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const ProfileAndSSD = memo(({handlerWithResultsScroll, allCustomizedFields}) => {
  const dispatch = useDispatch()
  const { performanceProfiles } = useSelector(requirementsSelector)
  const performance_profile = _.get(allCustomizedFields, 'performance_profile')
  const ssd_model = _.get(allCustomizedFields, 'ssd_model')
  const changeProfile = newPerformanceProfile => {
    const newProfile = _.get(newPerformanceProfile, 'value')
    const newSSDs = _.get(performanceProfiles, [newProfile, 'SSDs'])
    let newFields = {performance_profile: newProfile}
    if (!_.includes(newSSDs, ssd_model)) {
      newFields.ssd_model = _.get(newSSDs, 0)
    }
    dispatch(setCustomRaFields({...allCustomizedFields, ...newFields}))
  }
  return (
    <div className="performance-profile-and-ssd">
      <div className="requirement-row">
        <b>Performance profile</b>
        <Dropdown
          key={performance_profile}
          className="profile"
          options={_.map(performanceProfiles, (value, key) => ({value: key, label: _.get(value, 'label')}))}
          selected={performance_profile}
          managedState={false}
          onChange={handlerWithResultsScroll(changeProfile)}
        />
      </div>
      <div className="requirement-row">
        <b>SSD</b>
        <Dropdown
          key={ssd_model}
          className="ssd"
          options={_.map(_.get(performanceProfiles, [performance_profile, 'SSDs']),
              value => ({value, label: value}))}
          selected={ssd_model}
          managedState={false}
          onChange={handlerWithResultsScroll(
            (newSSDModel) => dispatch(setCustomRaFields({
              ...allCustomizedFields,
              ssd_model: _.get(newSSDModel, 'value')
            }))
          )}
        />
      </div>
    </div>
  )
})

export default ProfileAndSSD