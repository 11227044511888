import React, {memo, useEffect, useState} from "react"
import _ from "lodash"
import API_ENDPOINTS from '/src/app/apiEndpoints'
import { callAPI } from '/src/utils/apiUtils'
import { getSolutionHelpTooltips } from "./SolutionHelpTooltips"
import SolutionHelpView from "./SolutionHelp.view"


const SolutionHelp = memo(({ setOpenPerformanceSubTooltip, openPerformanceSubTooltip, option, hiddenOutput }) => {
  const [ numberOfBuckets, setNumberOfBuckets] = useState({})
  const [ numberOfBucketsTooltip, setNumberOfBucketsTooltip] = useState()
  const [ numberOfFilesAndDirsSubTooltip, setNumberOfFilesAndDirsSubTooltip] = useState()
  const { be_cores, memory, parity, populated_ssds, servers, total_be_cores, total_ssd_cores, stripe } = option
  const { spineBWSubTooltip, memorySubTooltip, filesAndDirsTooltip } = getSolutionHelpTooltips(option)
  const numberOfFilesAndDirsTitle = '# of files and directories'

  useEffect(() => {
    const payload = { data_drives: stripe, protection_drives: parity, compute_nodes: total_be_cores, memory,
      io_nodes_per_host: be_cores, drives_count: populated_ssds * servers,
      total_capacity: _.round(total_ssd_cores * Math.pow(1024, 4))
    }
    callAPI({ endpoint: API_ENDPOINTS.GET_BUCKET_LIMIT, payload,
      callback: ( data ) => {
        setNumberOfBuckets(data)
        setNumberOfBucketsTooltip(
          <div>
            {_.map(data, (value, key) => (
              <div key={_.kebabCase(`number-of-buckets-${key}-sub-tooltip`)}>
                <div><b>{key}</b></div>
                <div>{value}</div>
              </div>
            ))}
          </div>
        )
        setNumberOfFilesAndDirsSubTooltip(filesAndDirsTooltip({bucketLimitData: data}))
      },
      errorHandler : err => {
        console.log(err)
        setNumberOfBuckets({error: 'N/A'})
        setNumberOfFilesAndDirsSubTooltip(filesAndDirsTooltip({}))
      },
      timeout: 3000
    })
  }, [option])

  return (
    <SolutionHelpView
      memorySubTooltip={memorySubTooltip}
      spineBWSubTootlip={spineBWSubTooltip}
      numberOfBuckets={numberOfBuckets}
      numberOfBucketsTooltip={numberOfBucketsTooltip}
      numberOfFilesAndDirsTitle={numberOfFilesAndDirsTitle}
      numberOfFilesAndDirsSubTooltip={numberOfFilesAndDirsSubTooltip}
      openPerformanceSubTooltip={openPerformanceSubTooltip}
      option={option}
      hiddenOutput={hiddenOutput}
      setOpenPerformanceSubTooltip={setOpenPerformanceSubTooltip}
    />
  )
})

export default SolutionHelp
