import React, { memo } from 'react'

export default memo(() => (
    <svg width="21px" height="19px" viewBox="0 0 21 19">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-225.000000, -801.000000)" fill="#6D7278">
                <path d="M245.792577,808.921575 C245.452577,809.341575 245.102577,809.771575 244.762577,810.191575 L242.002577,813.571575 C241.902577,813.691575 241.832577,813.751575 241.822577,813.781575 C241.782577,813.751575 241.722577,813.701575 241.632577,813.591575 L241.072577,812.891575 C239.992577,811.571575 238.912577,810.251575 237.842577,808.931575 C238.372577,808.921575 238.912577,808.921575 239.452577,808.921575 L240.662577,808.921575 L240.602577,808.371575 C240.412577,806.441575 238.342577,803.971575 235.592577,803.361575 C232.502577,802.681575 229.342577,804.151575 227.922577,806.951575 C226.482577,809.791575 227.182577,813.201575 229.642577,815.261575 C231.682577,816.961575 235.192577,817.681575 238.282577,815.411575 L239.622577,817.101575 C238.852577,817.721575 238.012577,818.181575 237.112577,818.471575 C236.632577,818.631575 236.142577,818.741575 235.622577,818.871575 C235.412577,818.921575 235.192577,818.971575 234.982577,819.021575 L233.122577,819.021575 C233.042577,818.991575 232.942577,818.951575 232.822577,818.931575 C231.202577,818.721575 229.772577,818.121575 228.562577,817.161575 C225.652577,814.851575 224.522577,811.881575 225.182577,808.361575 C225.852577,804.821575 228.022577,802.461575 231.642577,801.361575 C232.721577,801.000575 234.390577,800.883575 235.512577,801.131575 C235.912577,801.211575 236.292577,801.291575 236.652577,801.411575 C240.002577,802.521575 242.032577,804.711575 242.882577,808.091575 L243.102577,808.921575 L245.792577,808.921575 Z"></path>
            </g>
        </g>
    </svg>
))
