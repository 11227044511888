import React, { memo } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { Button } from '/src/storybook/components/Button'
import { Checkbox } from '/src/storybook/components/Checkbox'
import { Input } from '/src/storybook/components/Input'


const Option = memo(({
    option,
    selectedForExport,
    setSelectedForExport,
    weka_total_cost,
    index,
    isWarm,
    rowValues,
    tooltips,
    setBOMModalData
  }) => {
  const displayCost = _.get(option, 'display_cost')
  const selected = selectedForExport && (index in selectedForExport)
  const isChannel = _.startsWith(window.location.hostname, 'channel.')
  return (
    <div className={classNames("results-row", {selected, "for-partner": !weka_total_cost})}>
      <div>
        <Checkbox
          key={`selected-options-${_.size(selectedForExport)}`}
          checked={selected}
          onChecked={(value) => {
            setSelectedForExport(value ?
              {...selectedForExport, [index]: {option, comment: ''}} :
              _.pickBy(selectedForExport, (val, key) => key !== index))
          }}
        />
      </div>
      <div>
        {rowValues[0]}
        {
          selected &&
          (
            <Input
              isTag
              id='comment'
              placeholder='+ Tag'
              onChange={comment => setSelectedForExport({...selectedForExport, [index]: {option, comment}})}
            />
          )
        }
      </div>
      <div>{rowValues[1]}{tooltips[0]}</div>
      <div className="center">{rowValues[2]}{tooltips[1]}</div>
      <div className="center">{rowValues[3]}</div>
      <div className="center">{rowValues[4]}</div>
      <div className="center">{rowValues[5]}{tooltips[2]}</div>
      <div className="center">{rowValues[6]}{tooltips[3]}</div>
      <div className="center">
        <>
          {rowValues[7]}
          {isWarm && tooltips[4]}
        </>
      </div>
      {weka_total_cost && /* Means we are not in partner */
        <div className="center">{rowValues[8]}{displayCost && tooltips[5]}</div>
      }
      {!isChannel && (
        <div>
          {
            _.get(option, 'bom_dialog') &&
            (
              <Button className="bom-button" onClick={() => setBOMModalData(option)} label='BOM'/>
            )
          }
        </div>
      )}
    </div>
  )
})

export default Option