import React, {memo, useRef, useState} from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Header } from '/src/routes/SizerCalculator/Header'
import { Footer } from '/src/storybook/components/Footer'
import { Loader } from '/src/storybook/components/Loader'
import { RequirementsPane, requirementsSelector } from './RequirementsPane'
import { ResultsPane, setResults, resultsSelector } from '/src/routes/SizerCalculator/ResultsPane'
import './SizerCalculator.scss'


const SizerCalculatorView = memo(({
    hiddenRequirements,
    hiddenOutput,
    partnerVendors,
    showExactOption,
    jsonName,
    calculating,
    user
  }) => {
  const requirements = useSelector(requirementsSelector)
  const results = useSelector(resultsSelector)
  const [ exportMode, setExportMode] = useState(false)
  const contentRef = useRef(null)
  const { cold_capacity, read_part, selectedVendors, warmCostAndSpace, ioSize, ioSizeUnit } = requirements
  const hostname = window.location.hostname
  const initialLoading = _.isNull(_.get(requirements, 'vendors')) // For rendering everything nicely only after the first calculation is done
  const handlerWithResultsScroll = (f) => (...args) => {
    f(...args)
    contentRef.current.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <div className="sizer-calculator">
      <Header user={user}/>
      <div className="content" ref={contentRef}>
        <RequirementsPane
          showExactOption={showExactOption}
          hostname={hostname}
          disabled={exportMode}
          hiddenRequirements={hiddenRequirements}
          exposeCustomRA={_.isNil(partnerVendors) || _.get(results, 'expose_custom_RA')}
          partnerVendors={partnerVendors}
          jsonName={jsonName}
          handlerWithResultsScroll={handlerWithResultsScroll}
          setResults={setResults}
          user={user}
        />
        {!initialLoading && <ResultsPane
          calculating={calculating}
          results={results}
          showExactOption={showExactOption}
          isPartner={Boolean(partnerVendors || hiddenRequirements)} //TODO: Move to Redux
          hiddenOutput={hiddenOutput}
          selectedVendors={selectedVendors}
          exportMode={exportMode}
          setExportMode={setExportMode}
          isWarm={cold_capacity > 0 && warmCostAndSpace}
          read_part={read_part}
          ioSize={ioSize}
          ioSizeUnit={ioSizeUnit}
          user={user}
        />}
        { calculating && <Loader isHV={_.startsWith(hostname, 'hv.')}/> }
      </div>
      {!initialLoading && <Footer calculating={calculating}/>}
    </div>
  )
})

export default SizerCalculatorView
