import React, { useState } from 'react'
import Tooltip from 'rc-tooltip'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Help from '../../icons/Help'

import 'rc-tooltip/assets/bootstrap_white.css'
import './TooltipHolder.scss'


/**
 * Primary UI component for user interaction
 */
const TooltipHolder = ({ content, holder, placement, className, holderClassName, trigger, align, ...props }) => {
  const [ visible, setVisible ] = useState(false)
  return (
    <Tooltip
      className={classNames({visible, 'help-icon': !holder})}
      onVisibleChange={() => setVisible(!visible)}
      placement={placement}
      trigger={trigger}
      overlay={content}
      align={align}
      overlayClassName={className}
      arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
    >
      <a className={holderClassName} {...props}>
        {holder || <Help/>}
      </a>
    </Tooltip>
  )
}

TooltipHolder.propTypes = {
  /**
   * Help content
   */
  content: PropTypes.element,
  /**
   * Help className
   */
  className: PropTypes.string,
  /**
   * Placement
   */
  placement: PropTypes.oneOf(['left','right','top','bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
  /**
   * Align
   */
  align: PropTypes.object,
  /**
   * Trigger
   */
  trigger: PropTypes.oneOf(["hover", "click", "focus"]),
  /**
   * Holder element
   */
  holder: PropTypes.element,
  /**
   * holder ClassName
   */
  holderClassName: PropTypes.string,
};

TooltipHolder.defaultProps = {
  placement: "bottom",
  trigger: "click"
}

export default TooltipHolder