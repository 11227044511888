import _ from "lodash"

export const formatNumber = size => {
  const power = 1000
  let n = 0
  const power_labels = {0: '', 1: 'K', 2: 'M', 3: 'B', 4: 'T'}
  while (size > power && n < _.size(power_labels) - 1) {
    size = size / 1000
    n = n + 1
  }
  let amount = _.round(size).toLocaleString()
  if (_.size(_.toString(amount)) === 1) {
    amount = _.round(size, 1).toLocaleString()
  }
  return amount + power_labels[n]
}