import React, { memo } from 'react'
import { useDispatch } from "react-redux"
import _ from "lodash"

import { Input } from '/src/storybook/components/Input'
import { setCustomRaFields } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const Stripe = memo(({ allCustomizedFields, handlerWithResultsScroll }) => {
  const dispatch = useDispatch()
  const stripe = _.get(allCustomizedFields, 'stripe')
  const parity = _.get(allCustomizedFields, 'parity')

  return (
    <div className="requirement-row">
      <b>Stripe size</b>
      <Input
        key={stripe}
        className="stripe"
        size="small"
        value={stripe}
        isNumber
        onChange={handlerWithResultsScroll(newStripe => {
          dispatch(setCustomRaFields({...allCustomizedFields, stripe: newStripe}))
        })}
      />
      <b>+ {parity}</b>
    </div>
  )
})

export default Stripe
