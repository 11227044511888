import React, { memo } from 'react'

export default memo(() => (
  <svg width="16px" height="16px" viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-846.000000, -530.000000)">
            <g transform="translate(376.000000, 508.000000)">
                <g transform="translate(449.000000, 21.000000)">
                    <g transform="translate(21.000000, 1.000000)">
                        <path className="background" d="M8,0 C12.4266667,0 16,3.57333333 16,8 C16,12.4266667 12.4266667,16 8,16 C3.57333333,16 0,12.4266667 0,8 C0,3.57333333 3.57333333,0 8,0 Z" fill="#000000"></path>
                        <g transform="translate(3.915789, 3.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <circle cx="4.08421053" cy="8.93684211" r="1"></circle>
                            <path d="M1.70526316,4.2 C2.14736842,4.2 2.50526316,3.84210526 2.50526316,3.4 C2.50526316,2.53684211 3.22105263,1.82105263 4.08421053,1.82105263 L4.08421053,1.82105263 C5.01052632,1.82105263 5.66315789,2.41052632 5.66315789,3.23157895 C5.66315789,3.65263158 5.55789474,3.84210526 5.17894737,4.17894737 L4.77894737,4.51578947 C4.67368421,4.6 4.27368421,4.91578947 4.29473684,4.89473684 C3.62105263,5.44210526 3.30526316,5.98947368 3.30526316,6.89473684 C3.30526316,7.33684211 3.66315789,7.69473684 4.10526316,7.69473684 C4.54736842,7.69473684 4.90526316,7.33684211 4.90526316,6.89473684 C4.90526316,6.49473684 4.96842105,6.38947368 5.30526316,6.11578947 L5.41052632,6.03157895 L5.53684211,5.92631579 C5.64210526,5.84210526 5.72631579,5.77894737 5.81052632,5.69473684 L6.21052632,5.35789474 C6.92631579,4.74736842 7.24210526,4.15789474 7.24210526,3.23157895 C7.24210526,1.50526316 5.83157895,0.242105263 4.08421053,0.242105263 C2.33684211,0.242105263 0.926315789,1.65263158 0.926315789,3.4 C0.926315789,3.4 0.926315789,3.4 0.926315789,3.4 C0.926315789,3.86315789 1.28421053,4.2 1.70526316,4.2 Z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
))
