import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Route, Redirect } from 'react-router'
import API_ENDPOINTS from '/src/app/apiEndpoints'
import { callAPI } from '/src/utils/apiUtils'
import { Page404 } from '/src/routes/Page404'
import config from '/src/config'
import { lastLoginTimeSelector, setUserData, setLastLoginTime, userDataSelector } from '/src/routes/Login'


const PrivateAdminRoute = ({ component: Component, ...rest }) => {
  const [ pageToRender , setPageToRender] = useState(null)
  const userData = useSelector(userDataSelector)
  const lastLoginTime = useSelector(lastLoginTimeSelector)
  const isAdmin = _.get(userData, 'isAdmin')
  const dispatch = useDispatch()

  useEffect(async () => {
    const hostname = window.location.hostname
    const afterLogin = config.AUTH_CLIENT.token.isLoginRedirect()

    if (afterLogin || (_.isEmpty(userData) || !isAdmin)) {
      callAPI({
        endpoint: API_ENDPOINTS.GET_ALLOWED_SITES,
        payload: {hostname},
        callback: async (data) => {
          const { backendVersion, partnername, hiddenRequirements, hiddenOutput, partnerVendors } = data
          localStorage.setItem('backendVersion', backendVersion)
          if (!afterLogin) {
            if (_.size(data) <= 1 && !_.includes(hostname, 'dev.weka.io') && hostname != 'localhost' && hostname != "sizer.weka.io") {
              setPageToRender(<Page404/>)
            } else {
              dispatch(setUserData({}))
              setPageToRender(<Redirect to="/login?redirect=/admin"/>)
            }
          } else {
            const { tokens } = await config.AUTH_CLIENT.token.parseFromUrl()
            config.AUTH_CLIENT.tokenManager.setTokens(tokens)
            await config.AUTH_CLIENT.token.getUserInfo().then((profileObj) => {
              const user = _.pick(profileObj, ['email', 'name'])
              callAPI({
                endpoint: API_ENDPOINTS.POST_IS_ADMIN,
                payload: {email: _.get(user, 'email')},
                callback: (data) => {
                  if (_.get(data, "is_admin", false)) {
                    if (partnername) {
                      dispatch(setUserData({isAdmin: true, ...user, hiddenRequirements, hiddenOutput, partnerVendors}))
                    } else {
                      dispatch(setUserData({isAdmin: true, ...user}))
                    }
                    dispatch(setLastLoginTime(new Date().getTime() / 1000))
                    setPageToRender(<Route {...rest} render={props => (<Component {...props} />)}/>)
                  } else {
                    showNotification({
                      message: "You are not allowed to enter admin site. Please contact your administrator",
                      type: "danger"
                    })
                  }
                }
              })
            })
          }
        }
      })
    } else if (!_.isNil(lastLoginTime) && (new Date().getTime() / 1000 - lastLoginTime) > 604800) { // A week
      dispatch(setUserData({}))
      setPageToRender( <Redirect to="/login?redirect=/admin" /> )
    } else {
      setPageToRender( <Route {...rest} render={props => (<Component {...props} />)} /> )
    }
  }, [userData])

  return pageToRender
}

export default PrivateAdminRoute
