import React, { memo } from 'react'
import Select from 'react-select' // TODO: Should be moved to storybook with some official component
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: Should be moved to storybook with some official component
import { faUserMinus, faUserPlus } from '@fortawesome/free-solid-svg-icons' // TODO: Should be moved to storybook with some official component
import { Input } from '/src/storybook/components/Input'
import './SizerAdmin.scss'


const SizerAdminView = memo(({
    whitelistUsers,
    vendors,
    onVendorsChange,
    addUser,
    deleteUser,
    renderCount,
    newEmail,
    setNewEmail,
    newVendors
  }) => {
  return (
    <div className="sizer-admin">
      <div className="header">
        <h1>Sizer Admin</h1>
      </div>
      <div className="content">
        <h2>Whitelist Users</h2>
        <div className="users-table">
          { 
            _.map(whitelistUsers, user => {   
              const email = _.get(user, "email")
              const userVendors = _.filter(vendors, v => _.includes(_.get(user, "vendors"), _.get(v, "value")))
              return (
                <React.Fragment key={email}>
                  <div>{email}</div>
                  <div>
                    <Select
                      className="select"
                      menuPortalTarget={document.body}
                      options={vendors} 
                      defaultValue={userVendors} 
                      isMulti
                      isClearable={false}
                      placeholder="Select vendors..."
                      onChange={(values, event) => onVendorsChange(values, event, email)}
                    />
                  </div>
                  <div className="actions">
                    <FontAwesomeIcon icon={faUserMinus} onClick={() => deleteUser(email)}/>
                  </div>
                </React.Fragment>
              )
            }) 
          }
          <div><Input key={renderCount} value={newEmail} onChange={setNewEmail} placeholder="User's email..."/></div>
          <div>
            <Select 
              options={vendors} 
              isMulti
              menuPortalTarget={document.body}
              ref={newVendors}
              placeholder="Select vendors..."
            />
          </div>
          <div className="actions">
            <FontAwesomeIcon icon={faUserPlus} onClick={addUser}/>
          </div>
        </div>
      </div>
    </div>
  )
})

export default SizerAdminView