import React from "react"
import _ from "lodash"
import ReactNotification, { store } from 'react-notifications-component'
import 'animate.css/animate.min.css'
import { SuccessNotification } from '/src/storybook/components/SuccessNotification'

// TODO: Can be removed if this issue is fixed: https://github.com/teodosii/react-notifications-component/issues/132
// TODO: and react-notifications-component is upgraded to the latest version
const notifications = _.get(ReactNotification, ["ReactNotifications", "store"], store)

export const showNotification = ({type, message}) => {
  /* istanbul ignore next */
  notifications.addNotification({
    message,
    type,
    container: "top-right",
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  })
}

export const showSuccessNotification = ({ message }) => {
  /* istanbul ignore next */
  notifications.addNotification({
    type: "success",
    container: "bottom-right",
    content: (<SuccessNotification message={message}/>),
    width: 230,
    animationIn: ["animate__animated", "animate__flipInY"],
    dismiss: {
      duration: 2000
    }
  })
}
