import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STRINGS } from '/src/app/consts'
import { labelModifiersSelector } from '/src/routes/Login'
import { Input } from '/src/storybook/components/Input'
import { Toggle } from '/src/storybook/components/Toggle'
import { setRequirement, requirementsSelector } from '../../RequirementsPane.module'


const HotAndWarmToggle = memo(({hotAndWarm, setHotAndWarm, handlerWithResultsScroll}) => {
  const { cold_capacity } = useSelector(requirementsSelector)
  const labelModifiers = useSelector(labelModifiersSelector)
  const strings = { ...STRINGS, ...labelModifiers }
  const { CAPACITY_HOT_LABEL, CAPACITY_HOT_AND_WARM_LABEL, REQ_WARM_CAPACITY_LABEL } = strings
  const dispatch = useDispatch()

  return (
    <>
      <Toggle
        id={"hot-and-warm"}
        leftLabel={ CAPACITY_HOT_LABEL }
        rightLabel={ CAPACITY_HOT_AND_WARM_LABEL }
        checked={hotAndWarm}
        onClick={handlerWithResultsScroll(newHotAndWarm => {
          setHotAndWarm(newHotAndWarm)
          if (!newHotAndWarm) {
            dispatch(setRequirement({cold_capacity: 0, warmCostAndSpace: true}))
          }
        })}
      />
      {hotAndWarm && 
        <div className="requirement-row cold-capacity">
          <b> { REQ_WARM_CAPACITY_LABEL }</b>
          <Input 
            size="small" 
            value={cold_capacity} 
            isNumber
            onChange={handlerWithResultsScroll(newColdCapacity =>
              dispatch(setRequirement({cold_capacity: parseInt(newColdCapacity)}))
            )}
          />
          <b>TB</b>
        </div>
      }
    </>
  )
})

export default HotAndWarmToggle