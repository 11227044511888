import React, { memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { Dropdown } from '/src/storybook/components/Dropdown'
import { CustomizeRa } from './CustomizeRa'
import { HotAndWarmToggle } from '../HotAndWarmToggle'
import { setRequirement } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'
import AdditionalFields from './AdditionalFields'
import BundleFields from './BundleFields'
import { Advanced } from "../Advanced"


const BySizingTabView = memo(({
    handlerWithResultsScroll,
    vOptions,
    vendor,
    raOptions,
    ra,
    hotAndWarm,
    setHotAndWarm,
    exposeCustomRA,
    comesInBundle,
    hiddenRequirements,
    network,
    activeTab,
    warmCostAndSpace
  }) => {
  const [ advancedOpen, setAdvancedOpen ] = useState(false)
  const dispatch = useDispatch()
  return (
    <div className="second-tab requirement-box">
      <HotAndWarmToggle hotAndWarm={hotAndWarm} setHotAndWarm={setHotAndWarm} handlerWithResultsScroll={handlerWithResultsScroll}/>
      <div className="requirement-row">
        <b>Vendor</b>
        <Dropdown className="vendor" options={vOptions} selected={vendor} onChange={handlerWithResultsScroll(
          (newVendor) => dispatch(setRequirement({vendor: _.get(newVendor, 'value'), customRaFields: null}))
        )}/>
      </div>
      <div className="requirement-row">
        <b>RA</b>
        <Dropdown className="ra" options={raOptions} selected={ra} onChange={handlerWithResultsScroll(
          (newRa) => dispatch(setRequirement({vendor, ra: _.get(newRa, 'value'), customRaFields: null}))
        )}/>
      </div>
      {exposeCustomRA && <div className="customize-ra">
        <CustomizeRa hiddenRequirements={hiddenRequirements} handlerWithResultsScroll={handlerWithResultsScroll} />
      </div>}
      { comesInBundle && <BundleFields handlerWithResultsScroll={handlerWithResultsScroll}/>}
      { !comesInBundle && <AdditionalFields handlerWithResultsScroll={handlerWithResultsScroll}/>}
      <Advanced
        key={network + hotAndWarm + activeTab}
        network={network}
        activeTab={activeTab}
        warmCostAndSpace={warmCostAndSpace}
        advancedOpen={advancedOpen}
        setAdvancedOpen={setAdvancedOpen}
        showWarmOptions={hotAndWarm}
        handlerWithResultsScroll={handlerWithResultsScroll}
      />
    </div>
  )
})

export default BySizingTabView
