import React, { memo } from 'react'
import {useDispatch, useSelector} from "react-redux"
import _ from "lodash"
import { Dropdown } from '/src/storybook/components/Dropdown'
import { Input } from '/src/storybook/components/Input'
import {
  requirementsSelector,
  setRequirement
} from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const AdditionalFields = memo(({ handlerWithResultsScroll }) => {
  const dispatch = useDispatch()
  const { numberOfEnclosures, SSDsPerEnclosure, ra, vendorOptions } = useSelector(requirementsSelector)

  let ssdOptions = []
  if (ra) {
    const raParams = _.find(vendorOptions, o => _.get(o, 'ra') === ra)
    ssdOptions = _.get(raParams, 'ssds')
    ssdOptions = _.map(ssdOptions, value => ({value, label: value}))
  }

  return (
    <>
      <div className="requirement-row number-of-enclosures">
        <div>
          <div><b># of</b></div>
          <b>Enclosures</b>
        </div>
        <Input
          className="enclosures"
          key={numberOfEnclosures}
          size="small"
          value={numberOfEnclosures}
          isNumber
          onChange={handlerWithResultsScroll(newNumberOfEnclosures =>
            dispatch(setRequirement({numberOfEnclosures: parseInt(newNumberOfEnclosures)}))
          )}
        />
      </div>
      <div className="requirement-row">
        <div>
          <div><b>SSDs Per</b></div>
          <b>Enclosures</b>
        </div>
        <Dropdown
          key={SSDsPerEnclosure}
          className="ssds-per-enclosure"
          options={ssdOptions}
          selected={SSDsPerEnclosure}
          onChange={handlerWithResultsScroll(newSSDs =>
            dispatch(setRequirement({SSDsPerEnclosure: _.get(newSSDs, 'value')}))
          )}/>
      </div>
    </>
  )
})

export default AdditionalFields