import React, { memo, useRef, useEffect, useState } from 'react'
import _ from 'lodash'
import { callAPI } from '/src/utils/apiUtils'
import API_ENDPOINTS from '/src/app/apiEndpoints'
import { Loader } from '/src/storybook/components/Loader'
import SizerAdminView from "./SizerAdmin.view"
import './SizerAdmin.scss'


const SizerAdmin = memo(({}) => {
  const [ newEmail, setNewEmail ] = useState("")
  const [ whitelistUsers, setWhitelistUsers ] = useState({})
  const [ vendors, setVendors ] = useState({})
  const [ renderCount, setRenderCount ] = useState(0)
  const newVendors = useRef(null)

  useEffect(() => {
    callAPI({
      endpoint: API_ENDPOINTS.GET_WHITELIST_USERS, 
      callback: (data) => {
        setWhitelistUsers(_.get(data, "data"))
      }
    })
    callAPI({
      endpoint: API_ENDPOINTS.GET_REQUIREMENTS_DATA,
      callback: (data) => {
        setVendors(_.map(_.get(data, 'vendors'), v => ({value: _.get(v, 0), label: _.get(v, 0)})))
      }
    })
  }, [renderCount])

  if (_.isEmpty(whitelistUsers) || _.isEmpty(vendors)) {
    return <Loader/>
  }

  const onVendorsChange = (values, event, email) => {
    const action = _.get(event, "action")
    if (action === "remove-value" || action === "select-option") {
      callAPI({
        endpoint: API_ENDPOINTS.UPDATE_WHITELIST_USER, 
        id: email,
        payload: {
          user: {
            vendors: _.map(values, v => _.get(v, "value"))
          }
        }, 
        callback: (data) => {
          setRenderCount(renderCount + 1)
        }
      })  
    }
  }

  const addUser = () => {
    callAPI({
      endpoint: API_ENDPOINTS.ADD_WHITELIST_USER, 
      payload: {
        user: {
          email: _.trim(newEmail),
          vendors: _.map(newVendors.current.state.value, v => _.get(v, "value"))
        }
      }, 
      callback: (data) => {
        newVendors.current.state.value = null
        setNewEmail("")
        setRenderCount(renderCount + 1)
      }
    })
  }

  const deleteUser = (email) => {
    callAPI({
      endpoint: API_ENDPOINTS.DELETE_WHITELIST_USER, 
      id: email, 
      callback: () => {
        setRenderCount(renderCount + 1)
      }
    })
  }

  const props = {
    whitelistUsers,
    vendors,
    onVendorsChange,
    addUser,
    deleteUser,
    renderCount,
    newEmail,
    setNewEmail,
    newVendors
  }
  return <SizerAdminView {...props} />
})

export default SizerAdmin