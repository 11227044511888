import _ from 'lodash'
import { makeReducer } from '/src/utils/reduxUtils'

export const USER_DATA_KEY = 'USER'

// ACTIONS
const SET_USER_DATA = `${USER_DATA_KEY}.SET_USER_DATA`
export const setUserData = userData => ({
  type: SET_USER_DATA,
  payload: userData
})

const SET_LAST_LOGIN_TIME = `${USER_DATA_KEY}.SET_LAST_LOGIN_TIME`
export const setLastLoginTime = lastLoginTime => ({
  type: SET_LAST_LOGIN_TIME,
  payload: lastLoginTime
})

const SET_LABEL_MODIFIERS = `${USER_DATA_KEY}.SET_LABEL_MODIFIERS`
export const setLabelModifiers = labelModifiers => ({
  type: SET_LABEL_MODIFIERS,
  payload: labelModifiers
})

// SELECTORS
export const userDataSelector = state => _.get(state, [USER_DATA_KEY, 'user'])
export const lastLoginTimeSelector = state => _.get(state, [USER_DATA_KEY, 'lastLoginTime'])
export const labelModifiersSelector = state => _.get(state, [USER_DATA_KEY, 'labelModifiers'])

const INITIAL_STATE = {
  user: {},
  lastLoginTime: null,
  isAdmin: false,
  labelModifiers: {}
}

export default makeReducer({
  [SET_USER_DATA]: (state, { payload }) => ({
    ...state,
    user: payload
  }),
  [SET_LAST_LOGIN_TIME]: (state, { payload }) => ({
    ...state,
    lastLoginTime: payload
  }),
  [SET_LABEL_MODIFIERS]: (state, { payload }) => ({
    ...state,
    labelModifiers: payload
  })
}, INITIAL_STATE)