import React, { memo } from 'react'

export default memo(({className}) => (
    <svg width="34px" height="36px" viewBox="0 0 34 36" className={className}>
        <defs>
            <filter x="-31.6%" y="-88.8%" width="163.1%" height="277.5%" filterUnits="objectBoundingBox" id="filter-copy">
                <feOffset dx="0" dy="9" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0321787587 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-381.000000, -475.000000)" fill="#000000" fillRule="nonzero">
                <g transform="translate(376.000000, 464.000000)" filter="url(#filter-copy)">
                    <g>
                        <path d="M23.4578152,14.6668527 C24.7508183,14.6668527 25.8011669,15.7173937 25.8011669,17.0102044 L25.8011669,25.6566483 C25.8011669,26.9496514 24.7506259,28 23.4578152,28 L17.3433517,28 C16.0503486,28 15,26.949459 15,25.6566483 L15,17.0102044 C15,15.7172013 16.050541,14.6668527 17.3433517,14.6668527 L23.4578152,14.6668527 Z M23.4578152,16.0134968 L17.3433517,16.0134968 C16.7910876,16.0134968 16.3467981,16.4579787 16.3467981,17.0100505 L16.3467981,25.6564943 C16.3467981,26.2087585 16.79128,26.653048 17.3433517,26.653048 L23.4578152,26.653048 C24.0100793,26.653048 24.4543688,26.2085661 24.4543688,25.6564943 L24.4543688,17.0100505 C24.4543688,16.4577863 24.0098869,16.0134968 23.4578152,16.0134968 Z M24.5486831,12 C26.6900152,12 28.4274617,13.7372926 28.4274617,15.8787786 L28.4274617,23.8114194 C28.4274617,24.1750241 28.1311969,24.4848569 27.7540626,24.4848569 C27.3769669,24.4848569 27.0807021,24.1885921 27.0807021,23.8114579 L27.0807021,15.878817 C27.0807021,14.478224 25.9494686,13.3467981 24.5486831,13.3467981 L19.1614907,13.3467981 C18.7843565,13.3467981 18.4880917,13.0505333 18.4880917,12.6733991 C18.4880917,12.2962648 18.7843565,12 19.1614907,12 L24.5486831,12 Z"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
))
