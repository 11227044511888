import React, { memo } from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import './ExportContent.scss'
import './RADetails.scss'


const ExportContent = memo(({data, raDetailsHeader, raDetails }) => {
  return (
    <div className="options-content">
      { _.map(data, (option, optionIndex) => (
        <div className="results dont-page-break" key={optionIndex}>
          { _.size(option) === 3 && _.map(option, (row, rowIndex) => {
            const rowClass = rowIndex === 0 ? "header-row" : (rowIndex === 1 ? "full-row" : "values-row")
            return _.map(row, (cell, cellIndex) => (
              <div
                key={`${optionIndex}-${rowIndex}-${cellIndex}`}
                className={classNames("cell", "dont-page-break", rowClass)}
              >
                { cell }
              </div>
            ))
          })}
          { _.size(option) === 2 && _.map(option, (row, rowIndex) => {
            const rowClass = rowIndex === 0 ? "full-row" : "values-row"
            return _.map(row, (cell, cellIndex) => (
              <div
                key={`${optionIndex}-${rowIndex}-${cellIndex}`}
                className={classNames("cell", "dont-page-break", rowClass)}
              >
                { cell }
              </div>
            ))
          })}
        </div>
      ))}
      <div className="section-header dont-page-break"><b>RA DETAILS</b></div>
      <div className="ra-details">
        <div className="column-titles dont-page-break">
          { _.map(raDetailsHeader, (cell, cellIndex) => (
            <div
              key={`ra-details-header-${cellIndex}`}
              className="cell header-row dont-page-break"
            >
              { cell }
            </div>
          ))}
        </div>
        { _.map(raDetails, (ra, raIndex) => (
          <div className="dont-page-break">
            <div className="main-values dont-page-break">
              { _.map(_.get(ra, "values"), (cell, cellIndex) => (
                <div
                  key={`ra-details-${raIndex}-${cellIndex}`}
                  className="cell dont-page-break"
                >
                  { cell }
                </div>
              ))}
            </div>
            <div className="secondary-values dont-page-break">
              { _.map(_.get(ra, "secondaryValues"), (pair, pairIndex) => (
                <div key={`ra-details-secondary-${raIndex}-${pairIndex}`} className="cell dont-page-break">
                  <b>{_.get(pair, 0)}</b>
                  <span>{_.get(pair, 1)}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )}
)

export default ExportContent