import _ from 'lodash'
import { makeReducer } from '/src/utils/reduxUtils'
import { SIZER_CALCULATOR_KEY } from '/src/routes/SizerCalculator'

export const RESULTS_KEY = 'RESULTS'

// ACTIONS
const SET_RESULTS = `${RESULTS_KEY}.SET_RESULTS`
export const setResults = results => ({
  type: SET_RESULTS,
  payload: results
})

// SELECTORS
// TODO: Check how can we get here only the relevant state
export const resultsSelector = state => _.get(state, [SIZER_CALCULATOR_KEY, RESULTS_KEY])

export const customizedFieldsSelector = state => {
  const exactOption = _.get(state, [SIZER_CALCULATOR_KEY, RESULTS_KEY, 'exact_option'])
  return _.pick(exactOption,['network_ports', 'port_speed', 'cpu_clock', 'be_cores', 'ssd_cores', 'stripe',
    'parity', 'hotspare', 'memory', 'frontend_cores', 'performance_profile', 'ssd_model'])
}

export const defaultFieldsSelector = state => _.get(state, [SIZER_CALCULATOR_KEY, RESULTS_KEY, 'exact_option',
  'default_ra_fields'])

const INITIAL_STATE = []

export default makeReducer({
  [SET_RESULTS]: (state, { payload }) => ({
    ...state,
    error: null,
    exact_option_error: null,
    ...payload
  }),
}, INITIAL_STATE)
