import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import mixpanel from 'mixpanel-browser'
import config from '/src/config'
import API_ENDPOINTS from '/src/app/apiEndpoints'
import { aborter, callAPI } from '/src/utils/apiUtils'
import { requirementsSelector } from './RequirementsPane'
import { setResults } from '/src/routes/SizerCalculator/ResultsPane'
import { setLabelModifiers, userDataSelector } from '/src/routes/Login'
import SizerCalculatorView from "./SizerCalculator.view"
import './SizerCalculator.scss'


const SizerCalculator = memo(({ hiddenRequirements, hiddenOutput, partnerVendors }) => {
  const requirements = useSelector(requirementsSelector)
  const user = useSelector(userDataSelector)
  const { sizerPartnerToken, email } = user
  const [ calculating, setCalculating] = useState(false)
  const dispatch = useDispatch()
  const { activeTab, capacity, feature_set, sorting } = requirements
  const hostname = window.location.hostname
  const showExactOption = activeTab !== 'BY REQUIREMENT'
  const eventType = showExactOption ? 'configuration by sizing' : 'configuration by requirement'
  const jsonName = new URLSearchParams(window.location.search).get('jsonName')
  if (config.MIXPANEL_ID) {
    mixpanel.init(config.MIXPANEL_ID, {debug: true, ignore_dnt: true})
    mixpanel.people.set({'user': _.get(user, 'email')})
    mixpanel.identify(_.get(user, 'email'))
  }

  useEffect(() => {
    setCalculating(true)
    const payload = {
      hostname,
      future_versions: feature_set === 'Future',
      capacity_gb: capacity * 1000,
      sizerPartnerToken: sizerPartnerToken,
      jsonName,
      sorting,
      ..._.omit(requirements, ['capacity', 'feature_set'])
    }

    callAPI({
      endpoint: API_ENDPOINTS.POST_CALC,
      payload,
      callback: ({ label_modifier, ...data }) => {
        if (data["errorMessage"]) {
          console.log(data["errorMessage"])
          console.log(data["stackTrace"])
        } else {
          if (label_modifier) {
            dispatch(setLabelModifiers(label_modifier))
          }
          dispatch(setResults(data))
          if (config.MIXPANEL_ID) {
            mixpanel.track('Requirements search', {
              user: email,
              hostname,
              ...payload
            })
          }
        }
        setCalculating(false)
      }
    })

    if (hostname !== "localhost" && !_.isEmpty(user)) {
      callAPI({
        endpoint: API_ENDPOINTS.POST_LOGGER,
        payload: {
          event_type: eventType,
          hostname,
          timezone_offset: new Date().getTimezoneOffset(),
          email,
          data: payload
        }
      })
    }
    return () => {
      _.forEach(aborter, (v, k) => {
        if (v) {
          v.abort()
          aborter[k] = null
        }
      })
    }
  }, [ requirements, sizerPartnerToken, email ])

  const props = { hiddenRequirements, hiddenOutput, partnerVendors, showExactOption, jsonName, calculating, user }
  return <SizerCalculatorView {...props}/>
})

export default SizerCalculator
