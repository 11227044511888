import _ from 'lodash'


export const getRequirementsForExport = (requirements, ioSize, ioSizeUnit) => {
  const cold_capacity = _.get(requirements, 'cold_capacity')
  const performanceType = _.get(requirements, 'performance_type') === 'iops' ? `${ioSize}${ioSizeUnit} IOP's` : "BW"
  const performanceValue = performanceType === "BW" ? [performanceType, _.get(requirements, 'bw'), 'GB/sec'] :
    [performanceType, _.get(requirements, 'iops'), 'M']
  return [
    {columnTitle: 'SYSTEM CAPACITY', columnContent: [
        ["Capacity (hot NVME)", _.get(requirements, 'capacity'), "TB"],
        cold_capacity === 0 ? ["Hot only"] : ["Warm (OBS)", cold_capacity, "TB"]
      ]
    },
    {columnTitle: 'PERFORMANCE', columnContent: [
        ["Type", performanceType],
        ["R/W", _.get(requirements, 'read_part'), '%'],
        performanceValue
      ]
    },
    {columnTitle: 'CUSTOMER AND DEAL', columnContent: [
        ["Feature set", _.get(requirements, 'feature_set')],
        ["Deployment type", _.capitalize(_.get(requirements, 'deployment_type'))]
      ]
    }
  ]
}

export const getExportReportData = (requirements, exportData, strings ) => {

  const ioSize = _.get(requirements, 'ioSize')
  const ioSizeUnit = _.get(requirements, 'ioSizeUnit')

  const { REFERENCE_ARCHITECTURE_NAME, NUMBER_OF_NVMES, NET_CAPACITY, IOPS, BW, NUMBER_OF_ENCLOSURES, NUMBER_OF_SERVERS,
    STRIPING_SIZE, NUMBER_OF_HOT_SPARES, TOTAL_NVME_RAW_CAPACITY, TOTAL_NUMBER_OF_NETWORK_PORTS, NUMBER_OF_NVME_BAYS,
    NVME_CAPACITY, NVME_MODEL, NUMBER_OF_CPUS, CORES_PER_CPU, CPU_MODEL, CPU_CLOCK, NUMBER_OF_NETWORK_PORTS,
    NETWORK_PORT_SPEED, PORT_SPEED, US, SERVERS_PER_ENCLOSURES, NUMBER_OF_BUNDLES, BUNDLE_SKU, BUNDLE_SIZE,
    NUMBER_OF_CLIENT_HOSTS, MAX_NUMBER_OF_CLIENTS, SERVER_MODEL, MEMORY } = strings

  // Header titles
  const headers = [ REFERENCE_ARCHITECTURE_NAME, NUMBER_OF_NVMES, NET_CAPACITY, `${ioSize}${ioSizeUnit} ${IOPS}`,
    BW, NUMBER_OF_ENCLOSURES, NUMBER_OF_SERVERS, STRIPING_SIZE, NUMBER_OF_HOT_SPARES, TOTAL_NVME_RAW_CAPACITY,
    TOTAL_NUMBER_OF_NETWORK_PORTS, PORT_SPEED, NUMBER_OF_BUNDLES, NUMBER_OF_CLIENT_HOSTS, MAX_NUMBER_OF_CLIENTS ]

  // RA Details Header titles
  const raDetailsHeader = [ REFERENCE_ARCHITECTURE_NAME, NUMBER_OF_NVME_BAYS, NVME_CAPACITY, NUMBER_OF_CPUS,
    CORES_PER_CPU, CPU_CLOCK, NUMBER_OF_NETWORK_PORTS, NETWORK_PORT_SPEED, US, SERVERS_PER_ENCLOSURES, BUNDLE_SIZE,
    MEMORY ]

  // Building data for html template
  let data = []
  let raDetails = []
  let currentVendor = ""
  let raNames = []

  // TODO: Check why the order is not working
  const sortedData = _.sortBy(_.toPairs(exportData), 0)
  const options = _.map(sortedData, d => d[1])
  _.forEach(options, ({ option, comment }, index) => {
    const { vendor, instance_name, populated_ssds, capacity, iops, bw, enclosures, servers, striping, hotspare,
      total_servers_raw, total_ports, ssd_bays, ssd_capacity, ssd_model, cpus_number, cores_per_cpu, cpu_model,
      cpu_clock, network_ports, network_port_speed, port_speed, ra_us, servers_per_enclosures, number_of_bundles,
      number_of_client_hosts, max_number_of_clients, bundle_sku, bundle_size, server_model, memory } = option

    let headerRow = headers.slice()
    headerRow.unshift(vendor)
    let optionRow = ["Option " + (index + 1)]
    if (comment) {
      optionRow[0] += ': ' + comment
    }
    const valuesRow = ["", instance_name, populated_ssds, capacity, iops, bw, enclosures, servers, striping, hotspare,
      total_servers_raw, total_ports, port_speed, number_of_bundles, number_of_client_hosts, max_number_of_clients]
    if (currentVendor !== vendor) {
      data.push([headerRow, optionRow, valuesRow])
    } else {
      data.push([optionRow, valuesRow])
    }
    currentVendor = vendor

    if (!_.includes(raNames, instance_name)) {
      const raDetailsRow = [instance_name, ssd_bays, ssd_capacity, cpus_number, cores_per_cpu,
        cpu_clock, network_ports, network_port_speed, ra_us, servers_per_enclosures, bundle_size, memory]
      const raDetailsSecondaryRow = [
        [NVME_MODEL, ssd_model],
        [CPU_MODEL, cpu_model],
        [BUNDLE_SKU, bundle_sku],
        [SERVER_MODEL, server_model]
      ]
      raDetails.push({values: raDetailsRow, secondaryValues: raDetailsSecondaryRow})
      raNames.push(instance_name)
    }
  })
  return {requirements: getRequirementsForExport(requirements, ioSize, ioSizeUnit), data, raDetailsHeader, raDetails}
}