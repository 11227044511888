import React from "react"
import ReactDOMServer from "react-dom/server"
import _ from "lodash"


export const valuesOfTooltip = (tooltip, tooltipTitles=null, tooltipValues=null) => {
  let textContent
  const htmlContent = ReactDOMServer.renderToString(tooltip.props.content)
  const largeTooltip = _.includes(htmlContent, "Warm(Object)")

  if (tooltipTitles) { // Handle dynamic tooltip
    textContent = htmlContent.replace(/<b>.*?<\/b>/g, ' ')
    textContent = textContent.replace(/<[^>]+>/g, '\t')
    const values = _.filter(textContent.split('\t'), v => _.size(_.trim(v)))
    let titles = _.filter(htmlContent.split('<b>'), t => _.includes(t, '</b>'))
    titles = _.map(titles, title => _.get(title.split('</b>'), 0))
    titles.shift() // Removing 'Details' header
    _.forEach(values, (value, index) => {
      tooltipValues[_.indexOf(tooltipTitles, titles[index])] = value
    })
    return tooltipValues
  } else {
    if (!largeTooltip) { // It's a tooltip with 2 columns or just row of spaces for partner
      textContent = htmlContent.replace(/<b>.*?<\/b>/g, ' ') // Remove left column
    }
    textContent = (textContent || htmlContent).replace(/<[^>]+>/g, '\t')
    if (largeTooltip) {
      return _.filter(textContent.split('\t'), v =>
        v === '0' || parseFloat(v) || parseFloat(v.substring(1)))
    } else {
      return _.filter(textContent.split('\t'), v => _.size(_.trim(v)))
    }
  }
}