import React from 'react'
import classNames from "classnames"
import Select from 'react-select'
import PropTypes from 'prop-types'
import './Dropdown.scss'
import _ from 'lodash'


const Dropdown = ({ options, selected, managedState, className, ...props }) => {
  const value = selected ? _.find(options, o => o.value === selected) : _.get(options, 0)
  const selectProps = managedState ? { value } : { defaultValue: value}
  return (
    <Select 
      options={options} 
      className={classNames('dropdown', className)}
      classNamePrefix="dropdown"
      {...props}
      {...selectProps}
    />
  )
}

Dropdown.propTypes = {
  /**
   * Dropdown options
   */
  options: PropTypes.array,
  /**
   * If the state of the dropdown is managed on the parent
   */
  managedState: PropTypes.bool,
  /**
   * Selected option value
   */
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Optional change handler
   */
  onChange: PropTypes.func,
};

Dropdown.defaultProps = {
  onChange: undefined,
  managedState: true
}

export default Dropdown