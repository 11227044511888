import _ from 'lodash'
import React, { memo } from 'react'

import { callAPI } from '/src/utils/apiUtils'
import { showNotification } from '/src/utils/notificationsUtils'
import API_ENDPOINTS from '/src/app/apiEndpoints'
import { Button } from '/src/storybook/components/Button'
import './ExpiredToken.scss'


const ExpiredToken = memo(({ tokenAPIPayload }) => {

  const clickHandler = () => {
    callAPI({
      endpoint: API_ENDPOINTS.POST_TOKEN, 
      payload: tokenAPIPayload,
      callback: ( data ) => {
        showNotification({
          message: "An email with access link was sent to your email address, please follow this link",
          type: "success",
        });
      }
    })
  }

  return (
    <div className="box expired-token">
      <div className="sign-in-text">
        Sign in to Sizer
      </div>
      <div className="work-for">
        Your session has expired
      </div>
      <div className="work-for">
        Press here for a new link to be sent
      </div>
      <Button onClick={clickHandler} label="Send me new link"/>
    </div>
  )
})

export default ExpiredToken
