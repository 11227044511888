import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import loginReducer, {USER_DATA_KEY} from '/src/routes/Login/Login.module'
import { SIZER_CALCULATOR_KEY, sizerCalculatorReducer } from '/src/routes/SizerCalculator'


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  [USER_DATA_KEY]: loginReducer,
  [SIZER_CALCULATOR_KEY]: sizerCalculatorReducer
})

export default createRootReducer