import React, { useState, useEffect } from 'react'
import classNames from "classnames"
import PropTypes from 'prop-types'
import _ from 'lodash'
import './Radio.scss'


const Radio = ({ name, selected, items, disabled, onSelected, ...props }) => {
  const [selectedItem, setSelectedItem] = useState(selected)

  useEffect(() => {
    if (!selected && items.length) {
      setSelectedItem(items[0].value)
    }
  }, [selectedItem, items, selected]);
  
  const onChange = (e) => {
    setSelectedItem(e.target.value)
    onSelected(e.target.value)
  }
  return (
    <div className={`radio with-${_.size(items)}`}>
      {_.map(items, (i) => {
        const value = _.get(i, 'value')
        const id = _.kebabCase(name) + _.kebabCase(value)
        return (
          <React.Fragment key={id}>
            <input
              id={id} 
              type="radio"
              name={name} 
              value={value} 
              checked={value === selectedItem} 
              onChange={onChange} 
              {...props}
            />
            <label className="label" htmlFor={id}>{_.get(i, 'label')}</label>
          </React.Fragment>
        )
      })}
      <div className="radio-background"></div>
    </div>
  )
}

Radio.propTypes = {
  /**
   * Radio name
   */
  name: PropTypes.string,
  /**
   * Disabled Radio
   */
  disabled: PropTypes.bool,
  /**
   * Radio Items
   */
  items: PropTypes.array,
  /**
   * Selected item
   */
  selected: PropTypes.string,
  /**
   * Optional change handler
   */
  onSelected: PropTypes.func,
};

Radio.defaultProps = {
  disabled: false,
  onSelected: undefined,
}

export default Radio
