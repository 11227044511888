import _ from "lodash"
import React, { memo } from 'react'
import {useDispatch} from "react-redux"
import { Radio } from '/src/storybook/components/Radio'
import { setCustomRaFields } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const PortSpeed = memo(({handlerWithResultsScroll, allCustomizedFields}) => {
  const dispatch = useDispatch()
  const port_speed = _.get(allCustomizedFields, 'port_speed', '').toString()

  // TODO: compare network and port_speed in customRA (maybe we need to unite
  return (
    <div className="requirement-row">
      <b>Network port speed</b>
      <Radio key={port_speed} name="network_port_speed" selected={port_speed} items={[
        {label: "100GB", value: '100'},
        {label: "200GB", value: '200'}
      ]} onSelected={handlerWithResultsScroll(newNetworkPortSpeed => dispatch(setCustomRaFields({
          ...allCustomizedFields, port_speed: newNetworkPortSpeed
        })))}
      />
    </div>
  )
})

export default PortSpeed