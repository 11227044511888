import _ from "lodash"
import React, { memo } from 'react'
import {useDispatch} from "react-redux"
import { Dropdown } from '/src/storybook/components/Dropdown'
import { setCustomRaFields } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'


const PortsNumber = memo(({handlerWithResultsScroll, allCustomizedFields}) => {
  const dispatch = useDispatch()
  const ports_number = _.map(Array.from(Array(8).keys()), value => {
    value = (value + 1).toString()
    return {value, label: value}
  })
  const network_ports = _.get(allCustomizedFields, 'network_ports', '').toString()

  return (
    <div className="requirement-row">
      <b># of network ports</b>
      <Dropdown
        key={network_ports}
        className="ports-number"
        options={ports_number}
        selected={network_ports}
        managedState={false}
        onChange={handlerWithResultsScroll(newPortsNumber => dispatch(setCustomRaFields({
          ...allCustomizedFields, network_ports: _.get(newPortsNumber, 'value')
        })))}
      />
    </div>
  )
})

export default PortsNumber