import _ from "lodash"
import React from "react"
import API_ENDPOINTS from '/src/app/apiEndpoints'
import { Page404 } from '/src/routes/Page404'
import { callAPI } from '/src/utils/apiUtils'
import { setUserData, setLastLoginTime } from '/src/routes/Login'
import { Route, Redirect } from 'react-router'

export const handleNotLoggedInUser = ({ hostname, jsonName, setPageToRender, sizerPartnerToken, goToPartnerLogin,
                                        dispatch, Component, rest, access_code}) => {
  let payload = { hostname }
  if (jsonName) {
    payload["jsonName"] = jsonName
  }

  callAPI({
    endpoint: API_ENDPOINTS.GET_ALLOWED_SITES, payload,
    callback: (data) => {
      localStorage.setItem('backendVersion', _.get(data, 'backendVersion'))
      if (_.size(data) <= 1 && !_.includes(hostname, 'dev.weka.io') && hostname != 'localhost' && hostname != "sizer.weka.io") {
        setPageToRender(<Page404/>)
      } else {
        const { partnername, emaildomain } = data
        if (!_.isEmpty(partnername)) {
          if (!_.isNil(sizerPartnerToken)) { // user has token
            // TODO: check if this block is needed
            // TODO: (After setting the user, I get logged in directly, I think, without coming here)
            callAPI({
              endpoint: API_ENDPOINTS.POST_TOKEN, payload: {token: sizerPartnerToken, hostname},
              callback: (tokenData) => {
                const { expired, error, email } = tokenData
                const visibleVendors = emaildomain ? _.get(data, "partnerVendors") : _.get(data, ["whitelist_users", email, "vendors"])
                if (expired) {
                  dispatch(setUserData({}))
                  goToPartnerLogin({emaildomain, partnername, hostname, data, email, isExpiredToken: true})
                }
                if (error) {
                  dispatch(setUserData({}))
                  goToPartnerLogin({emaildomain, partnername, hostname, data})
                }
                setPageToRender(<Route {...rest} render={props => (
                  <Component {...props} hiddenRequirements={_.get(data, "hiddenRequirements")}
                             hiddenOutput={_.get(data, "hiddenOutput")} partnerVendors={visibleVendors}/>)}/>)
              }
            })
          } else if (!_.isNil(access_code)) { // user has access_code and need to generate token
            payload["access_code"] = access_code
            callAPI({
              endpoint: API_ENDPOINTS.POST_TOKEN, payload,
              callback: (tokenData) => {
                const {error, email, token} = tokenData
                const visibleVendors = emaildomain ? _.get(data, "partnerVendors") : _.get(data, ["whitelist_users", email, "vendors"])
                if (error) {
                  const userEmail = email ? email.split('@')[0] : ''
                  goToPartnerLogin({emaildomain, partnername, hostname, data, email: userEmail, error})
                } else {
                  dispatch(setLastLoginTime(new Date().getTime() / 1000))
                  dispatch(setUserData({
                    name: email,
                    email,
                    sizerPartnerToken: token,
                    hiddenRequirements: _.get(data, "hiddenRequirements"),
                    hiddenOutput: _.get(data, "hiddenOutput"),
                    partnerVendors: visibleVendors
                  }))
                }
              }
            })
          } else {
            goToPartnerLogin({emaildomain, partnername, hostname, data})
          }
        } else {
          setPageToRender(<Redirect to="/login"/>)
          // Uncomment to debug partner login locally
          // goToPartnerLogin({emaildomain: "weka.io", partnername: "hpe", hostname: "localhost:5001", data: {}})
        }
      }
    }
  })
}