import React from "react"
import PropTypes from 'prop-types'
import Success from '../../icons/Success'
import './SuccessNotification.scss'

/**
 * Primary UI component for user interaction
 */
const SuccessNotification = ({ message }) => {
  return (
    <div className="success-notification">
      <Success/>
      <div className="notification-text">{message}</div>
    </div>
  )
}

SuccessNotification.propTypes = {
  /**
   * Message to show in the notification
   */
  message: PropTypes.string,
};

export default SuccessNotification
