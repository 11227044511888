import _ from "lodash"


export const nameHelpValues = {
  'Details': '',
  '# of NVMe bays': 'ssd_bays',
  'NVMe capacity': 'ssd_capacity',
  'NVMe model': 'ssd_model',
  '# CPUs': 'cpus_number',
  'Cores per CPU': 'cores_per_cpu',
  'CPU model': 'cpu_model',
  'CPU clock': 'cpu_clock',
  '# network ports': 'network_ports',
  'Network port speed': 'network_port_speed',
  'BE Cores': 'be_cores',
  'SSD Cores': 'ssd_cores',
  'CPU Generation': 'cpu_generation',
  'Rack Units': 'ra_us',
  'Servers per enclosures': 'servers_per_enclosures',
  'Bundle SKU': 'bundle_sku',
  'Bundle size (servers)': 'bundle_size',
  'Server Model': 'server_model'
}

export const serversEnclosuresHelpValues = {
  'Details': '',
  'NVMes per servers': 'populated_ssds',
  'Striping size': 'striping',
  '# of hot spares': 'hotspare',
  'Total NVMe raw capacity': 'total_servers_raw',
  'Total # of network ports': 'total_ports',
  'Port speed': 'port_speed',
  '# of bundles': 'number_of_bundles',
  'Max # of client hosts': 'number_of_client_hosts',
  'Max # of client nodes': 'max_number_of_clients',
  'Precise net capacity': 'capacity',
  'Total BE Cores': 'total_be_cores',
  'Total SSD Cores': 'total_ssd_cores',
  'Writes per day': 'writes_per_day',
  'Spine BW (Gbits/sec)': 'spine_bw',
  'Memory': 'memory'
}

export const iopsHelpValues = (readPart) => {
  const currentValue = _.includes([0, 100], readPart) ? {} : {[`${readPart}% reads`]: 'iops'}
  return {
    'Details': '',
    '100% reads': 'max_iops',
    ...currentValue,
    '100% writes': 'min_iops'
  }
}

export const bwHelpValues = (readPart) => {
  const currentValue = _.includes([0, 100], readPart) ? {} : {[`${readPart}% reads`]: 'bw'}
  return {
    'Details': '',
    '100% reads': 'max_bw',
    ...currentValue,
    '100% writes': 'min_bw'
  }
}

export const priceHelpValuesBlock1 = {
  'Cost breakdown ($K)': '',
  'Breakdown Titles': '',
  'Total hardware cost': 'hardware_cost_help',
  'Weka transfer price': 'weka_transfer_help',
  'Reseller license markup': 'reseller_license_markup_help',
  'Total': 'total_markup_help'
}

export const priceHelpValuesBlock2 = {
  'Cost effectiveness': '',
  'Capacity ($/TB)': 'cost/tb',
  'Performance ($/IOPS)': 'cost/iops',
  'Bandwidth ($/Gbytes/s)': 'cost/bw'
}

export const priceHelpValuesBlock3 = {
  'Floor space': '',
  'Rack units': 'u_space'
}

export const spaceHelpValuesBlock = {
  'Floor space': '',
  'Breakdown Titles': '',
  'Rack units': 'u_space'
}

export const priceTooltipColumns = ["Total", "Hot(NVME)", "Warm(Object)"]
