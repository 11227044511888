export const STRINGS = {
  REFERENCE_ARCHITECTURE_NAME: "Reference Architecture Name",
  SERVERS_ENCLOSURES: "Servers / Enclosures",
  NUMBER_OF_NVMES: "# of NVMes per server",
  NET_CAPACITY: "Net Capacity (TB)",
  IOPS: "IOPS (M)",
  BW: "BW (Gbytes/sec)",
  NUMBER_OF_ENCLOSURES: "# of Enclosures",
  NUMBER_OF_SERVERS: "# of servers",
  STRIPING_SIZE: "Striping size",
  NUMBER_OF_HOT_SPARES: "# of hot spares",
  TOTAL_NVME_RAW_CAPACITY: "Total NVMe raw capacity",
  TOTAL_NUMBER_OF_NETWORK_PORTS: "Total # of network ports",
  BUNDLE_SKU: "Bundle SKU",
  BUNDLE_SIZE: "Bundle size (servers)",
  SERVER_MODEL: "Server model",
  MEMORY: "Memory",

  NUMBER_OF_NVME_BAYS: "# of NVMe bays",
  NVME_CAPACITY: "NVMe capacity",
  NVME_MODEL: "NVMe model",
  NUMBER_OF_CPUS: "# CPUs",
  CORES_PER_CPU: "Cores per CPU",
  CPU_MODEL: "CPU model",
  CPU_CLOCK: "CPU clock",
  NUMBER_OF_NETWORK_PORTS: "# network ports",
  NETWORK_PORT_SPEED: "Network port speed",
  PORT_SPEED: "Port speed",
  US: "Us",
  SERVERS_PER_ENCLOSURES: "Servers per enclosures",
  NUMBER_OF_BUNDLES: "# of bundles",
  NUMBER_OF_CLIENT_HOSTS: "Max # of client hosts",
  MAX_NUMBER_OF_CLIENTS: "Max # of client nodes",

  VENDOR: "Vendor",
  PRICE: "Budgetary Cost Requirements ($K)",
  SPACE: "Space requirements (Us)",

  CAPACITY_LABEL: "Capacity",
  CAPACITY_SECONDARY_LABEL: "(hot NVME)",
  CAPACITY_HOT_LABEL: "Hot only",
  CAPACITY_HOT_AND_WARM_LABEL: "Hot & warm",
  REQ_WARM_CAPACITY_LABEL: "Warm(OBS)",
}

export const THEMING = {
  HV: {
    title: 'HCSF System Sizer',
    text: [
      'This sizer is designed to help with the sales conversation & process, providing high level performance & capacity numbers.',
      'Please do not share this screen directly with the customer.',
      'Engineering continually tests & characterizes performance as the platform evolves.',
      'Performance numbers provided is the expected real world aggregate performance. Actual results may vary.',
      'For assistance, please contact your local Technical Expert or Content Solutions overlay with further questions.'
    ]
  }
}
