import React, {memo, useState} from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import mixpanel from 'mixpanel-browser'
import config from '/src/config'
import { TooltipHolder } from '/src/storybook/components/TooltipHolder'
import { ExportFileNameModal } from '/src/storybook/modals/ExportFileNameModal'
import { ResultsGrid } from '/src/routes/SizerCalculator/ResultsPane/ResultsGrid'
import { requirementsSelector } from '/src/routes/SizerCalculator/RequirementsPane/RequirementsPane.module'
import { Export } from './Export'
import { getExportReportData } from './ResultsPaneExportServices'
import './ResultsPane.scss'


const ResultsPaneView = memo(({ calculating, allOptions, exportDropdown, exportMode, resetExport, isPartner,
    headers, selectedForExport, setSelectedForExport, exportNameModalIsOpen, setExportNameModalIsOpen, strings,
    setExportData, exportData, hiddenOutput, user }) => {
  const requirements = useSelector(requirementsSelector)
  const [ exportInProgress, setExportInProgress ] = useState(false)
  const { activeTab, cold_capacity, ioSize, ioSizeUnit, read_part, warmCostAndSpace } = requirements
  const showExactOption = activeTab !== 'BY REQUIREMENT'
  const isWarm = cold_capacity > 0 && warmCostAndSpace
  const isChannel = _.startsWith(window.location.hostname, 'channel.')
  return (
    <div className={classNames("results-pane", {calculating})}>
      <div className="results-counter">
        <div><b>YOUR OPTIONS</b></div>
        <div>
          <span className='counter'>
            <b>{_.size(allOptions)} </b>
            Results found
          </span>
        </div>
      </div>
      <div className="export-row">
        { exportMode ? exportDropdown : (
          <TooltipHolder
            content={<span>Please select at least one option for export</span>}
            holder={exportDropdown}
            trigger="hover"
            placement="right"
            className="export-dropdown-tooltip"
            holderClassName="export-dropdown-holder"
          />
        )}
        { exportMode && (
          <a className="clear-export" onClick={resetExport}>
            Clear selection
          </a>
        )}
      </div>
      <div className={classNames("results-header results-row ", {"for-partner": isPartner})}>
        <div></div>
        <div>{ _.get(headers, 0) }</div>
        <div>{ _.get(headers, 1) }</div>
        <div className="center servers-enclosures">{ _.get(headers, 2) }</div>
        <div className="center">{ _.get(headers, 3) }</div>
        <div className="center">{ _.get(headers, 4) }</div>
        <div className="center">{ _.get(headers, 5) }</div>
        <div className="center">{ _.get(headers, 6) }</div>
        <div className="center">{ _.get(headers, 7) }</div>
        {!isPartner && (<div className="center">{ _.get(headers, 8) }</div>)}
        {!isChannel && (<div></div>)}
      </div>
      <ResultsGrid results={allOptions} showExactOption={showExactOption} user={user}
        selectedForExport={selectedForExport} setSelectedForExport={setSelectedForExport} isWarm={isWarm}
        read_part={read_part} hiddenOutput={hiddenOutput}/>
      <ExportFileNameModal
        isOpen={exportNameModalIsOpen}
        setIsOpen={setExportNameModalIsOpen}
        exportInProgress={exportInProgress}
        setExportInProgress={setExportInProgress}
        exportHandler={(exportName) => {
          const actualExportData = getExportReportData(requirements, selectedForExport, strings)
          setExportData({...actualExportData, exportName, resetExport})
          if (config.MIXPANEL_ID) {
            mixpanel.track('PDF export', {
              user: _.get(user, 'email'),
              exportName,
              hostname: window.location.hostname,
              ...actualExportData
            })
          }
        }}
      />
      <div className="exportContainer">
        <Export exportData={exportData} setExportNameModalIsOpen={setExportNameModalIsOpen}
                setExportInProgress={setExportInProgress}/>
      </div>
    </div>
  )
})

export default ResultsPaneView
